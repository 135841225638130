import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/ScreenRegistratoin.Text";
import {ScreenRegistration as ScrRegist, ShootingRange} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import {useParams} from "react-router";

function ScreenRegistrationWrapper(){
    return <ScreenRegistration liveguid={useParams().liveguid} />
}

export class ScreenRegistration extends Component<Props, State> {


    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: any) {
        super(props);
        this.state = {
            ranges: [],
            register: false
        }
    }

    async loadData() {
        let ranges = await this.loadRanges(false);
        if (ranges) {
            this.setState({ranges: ranges});
        }
    }

    async loadRanges(setState: boolean) {
        try {
            let ranges = await this.apiClient.api.coreShootingRangesList();
            if (ranges.ok) {
                if (setState) {
                    this.setState({ranges: ranges.data})
                }
                return ranges.data;
            }
        } catch (e: any) {

        }
    }

    screenRegister: ScrRegist = {
        screenId: this.props.liveguid,
        guid: this.props.liveguid,
        screenName: "screenName",
        shootingRange: {
            id: "",
            name: "",
            city: "",
            street: "",
            zipCode : "",
            shootingRangeDisciplines : undefined,
            description: ""
        }

    }

    async registerScreen() {
        try {
            let result = await this.apiClient.api.coreScreenRegistrationCreate(this.screenRegister);
            this.setState({
                register: result.ok
            });
        } catch (e: any) {

        }
    }

    componentDidMount() {
        this.loadData();
    }

    handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "DisplayName"){
            this.screenRegister.screenName = event.target.value;
        }
    };

    handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        if(event.target.name == "ShootingRange"){
            this.screenRegister.shootingRange =this.state.ranges.find((range) => range.id == event.target.value);
        }
    };

    render() {

        if(this.state.register){
            return <h1>{localizedTexts.ScreenRegistert}</h1>
        }else {
            return <div>
                    <div className="mb-3">
                        <label htmlFor="ShootingRange" className="form-label">{localizedTexts.ShootingRange}</label>
                        <select onChange={(event) => {this.handleChangeSelect(event)}} name={"ShootingRange"} className="form-select" aria-label="Default select example">

                            {this.state.ranges.map((range) => {
                                let id = range.id;
                                if (id === null || id === undefined) {
                                    id = "";
                                }
                                return <option key={range.id} value={id}>{range.name}</option>
                            })}
                            <option>{localizedTexts.PleaseSelect}</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">{localizedTexts.DisplayName}</label>
                        <input onChange={(event) => this.handleChangeInput(event)} name={"DisplayName"} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                    </div>
                    <button onClick={() => this.registerScreen()} className="btn btn-primary">{localizedTexts.ScreenRegister}</button>
            </div>
        }

    }
}


interface State {
    ranges: ShootingRange[];
    register: boolean;
}

interface Props {
    liveguid: string | undefined;
}

export default ScreenRegistrationWrapper;