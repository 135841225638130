import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Übersicht",
        "textSiteLoading" : "Seite lädt. Bitte einen Moment Geduld haben.",
        "practiceShortName" : "P",
        "shotByShotShortName" : "E",
        "seriesShortName" : "S",
        "clearLogQuestion" : "Soll ein ClearLog ausgeführt werden?",
        "startMatchQuestion" : "Soll der Match gestartet werden?",
        "sendAbort" : "Abbruch",
        "targets" : "Scheiben"

    },
    "fr": {
        "title" : "aperçu",
        "textSiteLoading" : "La page est en cours de chargement. Veuillez patienter un instant.",
        "practiceShortName" : "E",
        "shotByShotShortName" : "C",
        "seriesShortName" : "S",
        "clearLogQuestion" : "Un ClearLog doit-il être exécuté ?",
        "startMatchQuestion" : "Faut-il lancer le match ?",
        "sendAbort" : "arrêt",
        "targets" : "cibles"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;