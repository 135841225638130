import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Ranglisten",
        "headerFields":"Felder",
        "headerPrograms":"Stiche",
        "labelAll":"Gesamt",
        "labelScoreList":"Rangliste",
        "labelGroupScoreList":"Gruppen Rangliste",
        "headerSection":"Sektion",
    },
    "fr": {
        "title" : "Classements",
        "headerFields":"Categories",
        "headerPrograms":"Cibles",
        "labelAll":"Total",
        "labelScoreList":"Classement",
        "labelGroupScoreList":"Classement des groupes",
        "headerSection":"Section"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;