import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "ranges" : "Schiessanlagen",
        "organizations": "Vereine",
        "members":"Mitglieder"
    },
    "fr": {
        "overview" : "aperçu",
        "ranges" : "stands de tir",
        "organizations": "associations",
        "members":"membres"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;