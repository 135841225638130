import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Anmeldung",
        "labelBarcode":"Schützennummer",
        "labelStartNr":"Startnummer",
        "labelFirstName":"Vorname",
        "labelLastName":"Nachname",
        "labelCity":"Ort",
        "labelOrganization":"Verein",
        "labelGroup":"Gruppe",
        "labelSelectOrganization":"Verein wählen...",
        "labelShooter":"Schütze",
        "labelPersonNumber":"Personennummer",
        "labelLicenseNumber":"Lizenznummer",
        "labelBirthdate":"Geburtstag",
        "labelIsWoman":"Dame",
        "labelStreet":"Strasse",
        "labelZipCode":"PLZ",
        "labelSelectDiscipline":"Disziplin wählen...",
        "labelDiscipline":"Stiche",
        "labelSelectWeapon":"Waffe wählen...",
        "labelTotalAmount":"Totalbetrag:",
        "buttonReSend":"Erneut Senden",
        "buttonSave":"Speichern",
        "buttonSaveAndPrint":"Speichern & {0} Drucken",
        "buttonNextParticipant":"Nächster Teilnehmer",
        "textPersonNotFound":"Person wurde nicht gefunden in Datenbank",
        "textNotSavedDialog":"Schütze ist noch nicht gespeichert. Zum nächsten Schützen wechseln?",
        "textWeaponNotSelectedDialog":"Waffe wurde noch nicht bei allen Stichen ausgewählt.",
        "textParticipantSaved":"Teilnehmer gespeichert.",
        "textParticipantSavenotSuccessful":"Speichern nicht erfolgreich. Bitte eingaben kontrollieren und nochmals speichern.",
        "textParticipantSavenotSuccessfulPERSONMODELERROR":"Speichern nicht erfolgreich. Bitte die Angaben des Teilnehmers kontrollieren und nochmals speichern.",
        "modalSearchTitle":"Person auswählen",
        "modalSelectLabel":"Auswählen",
        "modalMorePeopleLabel":"Es hat weitere Resultate",
        "textSiteLoading": "Seite lädt. Bitte einen moment Geduld haben.",
        "textSearchNoResults":"Suche ergab keine resultate",
        "textReSendOk":"Schütze wurde erneut gesendet",
        "textReSendNok": "Schütze konnte nicht gesendet werden, bitte erneut versuchen",
        "shooteroffsetmessage":"Wieviele Schützen wurden bereits auf dieser Seite gedruckt?"
    },
    "fr": {
        "title" : "Inscription",
        "labelBarcode":"No. du tireur",
        "labelFirstName":"Prénom",
        "labelLastName":"Nom de famille",
        "labelCity":"Lieu",
        "labelOrganization":"Association",
        "labelGroup":"Group",
        "labelSelectOrganization":"Choisir l'association...",
        "labelShooter":"Tireur",
        "labelPersonNumber":"No. de personne",
        "labelLicenseNumber":"No. de licence",
        "labelBirthdate":"Date d'anniversaire",
        "labelStreet":"Rue",
        "labelZipCode":"Code postal",
        "labelSelectDiscipline":"Choisir la discipline...",
        "labelDiscipline":"Passes",
        "labelSelectWeapon":"Choisir l'arme...",
        "labelTotalAmount":"Total:",
        "buttonReSend":"Envoyer à nouveau",
        "buttonSave":"Enregistrer",
        "buttonSaveAndPrint":"Enregistrer & Imprimer {0}",
        "buttonNextParticipant":"Prochain participant",
        "textPersonNotFound":"Personne non trouvée dans la base de données",
        "textNotSavedDialog":"Le tireur n'est pas encore enregistré. Passer au tireur suivant?",
        "textWeaponNotSelectedDialog":"L'arme n'a pas encore été sélectionnée pour tous les passes.",
        "textParticipantSaved":"Participant enregistré.",
        "textParticipantSavenotSuccessful":"L'enregistrement n'a pas réussi. Veuillez contrôler les données et les enregistrer à nouveau",
        "textParticipantSavenotSuccessfulPERSONMODELERROR":"L'enregistrement n'a pas réussi. Veuillez contrôler les données du participant et les enregistrer à nouveau.",
        "modalSearchTitle":"Choisir une personne",
        "modalSelectLabel":"Choisir",
        "modalMorePeopleLabel":"Il a d'autres résultats",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant.",
        "textSearchNoResults":"La recherche n'a donné aucun résultat",
        "textReSendOk":"Le tireur a été envoyé à nouveau",
        "textReSendNok": "Le contacteur n'a pas pu être envoyé, veuillez réessayer"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;