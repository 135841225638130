import {Component} from "react";
import {NavLink} from "react-router-dom";
import localizedTexts from "../../event/texts/EventManagementNavigation.texts";

function RangeManagementNavigationWrapper(){

    return <RangeManagementNavigation />
}

export class RangeManagementNavigation extends Component<RangeManagementNavigationProps, RangeManagementNavigationState>{

    render(){
        return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
            <NavLink to={"overview"} className="btn btn-outline-dark">
                <span className={"bi-book"}> </span>{localizedTexts.overview}
            </NavLink>
            <NavLink to={"shootingprograms"} className="btn btn-outline-dark">
                <span className={"bi-card-list"}> </span>{localizedTexts.shootingprograms}
            </NavLink>
            <NavLink to={"settings"} className="btn btn-outline-dark">
                <span className={"bi-sliders"}> </span>{localizedTexts.settings}
            </NavLink>
        </div>
    }
}

interface RangeManagementNavigationState {

}

interface RangeManagementNavigationProps {

}

export default RangeManagementNavigationWrapper;