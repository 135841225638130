import {Fragment} from "react";
import CoreNavigation from "./CoreNavigation";
import CoreOverview from "./CoreOverview";
import {Route, Routes } from 'react-router-dom';
import RangeAdministration from "./RangeAdministration";
import ScreenRegistrationWrapper, {ScreenRegistration} from "./ScreenRegistration";
import RangeManagementNavigationWrapper from "./navigations/RangeManagementNavigation";
import EventManagementOverviewWrapper from "../event/EventManagementOverview";
import EventParticipantsNavigationWrapper from "../event/navigations/EventParticipantsNavigation";
import EventManagementNavigationWrapper from "../event/navigations/EventManagementNavigation";
import RangeOverview from "./RangeOverview";
import RangeShootingPrograms  from "./RangeShootingprograms";
import RangeSettings from "./RangeSettings";

function CoreMainView() {

    return <div>
        <div className={"row"}>
            <div>
                <CoreNavigation></CoreNavigation>
            </div>
            <div>
                <Routes>
                    <Route path={"/ranges/:rangeId/:disciplineId/*"} element={<RangeManagementNavigationWrapper/>}/>
                </Routes>
            </div>
            <div className={"row"}>
                <Fragment>
                    <Routes>
                        <Route path={"/overview/"} element={<CoreOverview/>}/>
                        <Route path={"/"} element={<CoreOverview/>}/>
                        <Route path={"/ranges"} element={<RangeAdministration/>}/>
                        <Route path={"/ranges/:rangeId/:distanceId/overview"}
                               element={<RangeOverview/>}/>
                        <Route path={"/ranges/:rangeId/:distanceId/shootingprograms"}
                               element={<RangeShootingPrograms/>}/>
                        <Route path={"/ranges/:rangeId/:distanceId/settings"}
                               element={<RangeSettings/>}/>

                        <Route path={"/registration/:liveguid"} element={<ScreenRegistrationWrapper/>}/>
                    </Routes>
                </Fragment>
            </div>
        </div>
    </div>
}


export default CoreMainView;