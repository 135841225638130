import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Einstellungen",
    },
    "fr": {
        "title" : "Paramètres",
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;