import React, {Component} from "react";
import {useParams} from "react-router";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventOrganisationOverview.text"
import WinFireDropDown, {WinFireDropDownElement} from "../common/components/WinFireDropDown";
import {Participant} from "./models/Models";
import PrintDocument from "../common/Helpers/PrintDocument";

export default function EventOrganisationOverviewWrapper() {
    let {eventId} = useParams()
    return <EventOrganisationOverview eventId={eventId}/>
}


interface EventOrganisationOverviewState {
    organizations: { organizationNumber: string, organizationName: string }[];
    selectedOrganization: string | undefined;
    selectedOrganizationNumber: string | undefined;
    availableParticipants: Participant[];
    search: string;
    resultSheetDefinitions: { name: string, id: number, isBarcodeSheet: boolean }[];
    selectedParticipantListtoPrint: Participant[]
}

interface EventOrganisationOverviewProps {
    eventId: string | undefined;
}

export class EventOrganisationOverview extends Component<EventOrganisationOverviewProps, EventOrganisationOverviewState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventOrganisationOverviewProps) {
        super(props);
        this.state = {
            organizations: [],
            selectedOrganization: undefined,
            selectedOrganizationNumber: undefined,
            availableParticipants: [],
            search: "",
            resultSheetDefinitions: [],
            selectedParticipantListtoPrint: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handlePrintList = this.handlePrintList.bind(this);
    }

    componentDidMount() {
        this.loadData()
    }

    selectOrganization = (organization: { organizationNumber: string, organizationName: string }) => {
        if (organization.organizationName === localizedTexts.textOrganizationSelect ?? "") {
            this.setState({selectedOrganization: undefined, selectedOrganizationNumber: undefined});
        } else {
            this.setState({
                selectedOrganization: organization.organizationName,
                selectedOrganizationNumber: organization.organizationNumber,
                selectedParticipantListtoPrint: []
            });
        }

    }

    async loadData() {

        try {

            var resultSheets: { name: string, id: number, isBarcodeSheet: boolean }[] = [];
            var eventDetailsResponse = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            if (eventDetailsResponse.ok) {
                eventDetailsResponse.data.disciplines?.forEach(d => {
                    d.shootingProgramDefinitions?.forEach(spd => {
                        if (resultSheets.findIndex(rs => rs.id === spd.resultSheetDefinition?.id) === -1) {
                            resultSheets.push({
                                name: spd.resultSheetDefinition!.name!,
                                id: spd.resultSheetDefinition!.id!,
                                isBarcodeSheet: spd.resultSheetDefinition?.isBarcodeSheet ?? false
                            });
                        }
                    })
                })

            }

            var results = await this.apiClient.api.eventsParticipantsDetail(this.props.eventId ?? "");
            if (results.ok) {
                const organizationNumbers = results.data
                    .map(participant => participant.organization?.number)
                    .filter((number): number is string => number !== undefined);

                var organizations: { organizationNumber: string, organizationName: string }[] = [];
                organizations.push({
                    organizationName: localizedTexts.textOrganizationSelect ?? "",
                    organizationNumber: ""
                });
                var orgs = await this.apiClient.api.eventsOrganizationsDetail(this.props.eventId ?? "");
                if (orgs.ok) {
                    orgs.data.forEach(o => {
                        if (organizationNumbers.find(org => org === o.number ?? ""))
                            organizations.push({organizationNumber: o.number ?? "", organizationName: o.name ?? ""});
                    })
                }
                const firstOrganisation = organizations[organizations.length - 1];
                this.setState({
                    availableParticipants: results.data,
                    resultSheetDefinitions: resultSheets,
                    organizations: organizations,
                    selectedOrganization: firstOrganisation.organizationName,
                    selectedOrganizationNumber: firstOrganisation.organizationNumber
                });
            }
        } catch (e: any) {

        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({search: event.target.value.toLowerCase()});
    }

    handlePrintList(participant : Participant) {
        if(this.state.selectedParticipantListtoPrint.find( partNr => partNr.participantNumber?.localeCompare(participant.participantNumber as string) === 0)){
            const removedList = this.state.selectedParticipantListtoPrint.filter( partNr => partNr.participantNumber?.localeCompare(participant.participantNumber as string) !== 0);
            this.setState({selectedParticipantListtoPrint: removedList});
        }else{
            const addList = this.state.selectedParticipantListtoPrint
            addList.push(participant);
            this.setState({selectedParticipantListtoPrint: addList});
        }
    }

    printOrganisationLabels = async (orgParticipants: Participant[], resultsheedId: number, isBarcodeSheet: boolean) => {
        var offset = 0;
        if (isBarcodeSheet) {
            var offsetString = prompt(localizedTexts.shooteroffsetmessage);
            offset = parseInt(offsetString ?? "0");
        }

        var participantNumbers = orgParticipants.map(gp => gp.participantNumber!);
        if (participantNumbers && participantNumbers.length > 0) {
            var result = await this.apiClient.api.eventsParticipantsResultsheetCreate(resultsheedId, this.props.eventId ?? "", participantNumbers, {offset: offset});
            if (result.ok) {
                await PrintDocument.printPdfDocument(result);
            }
        }
    }

    printValue(): Participant [] {
        if(this.state.selectedParticipantListtoPrint.length > 0){
            return this.state.selectedParticipantListtoPrint
        }else {
            return this.state.availableParticipants
                .filter(participant => participant.organization?.number === this.state.selectedOrganizationNumber)
        }
    }

    render() {
        return <div className={"container"}>
            <h1><span className={"bi-people"}> </span>{localizedTexts.title}</h1>
            <div className={"row"}>
                <div className={"col-3 d-flex align-items-center"}>
                    <span className={"h3"}>{localizedTexts.labelOrganization}: </span>&nbsp;&nbsp;
                    <WinFireDropDown
                        buttonText={this.state.selectedOrganization ?? localizedTexts.textOrganizationSelect ?? ""}
                        elements={this.state.organizations.map(o => {
                            return {
                                sourceObject: o,
                                key: o.organizationNumber,
                                text: o.organizationName,
                                selectFunction: this.selectOrganization
                            } as WinFireDropDownElement;
                        })}
                        elementFilter={(a: string, b: { organizationNumber: string, organizationName: string }) => {
                            return b.organizationName.includes(a) || b.organizationNumber.includes(a);
                        }}/>
                </div>
                <div className={"col-3 d-flex align-items-center"}>
                    <span className={"h3"}>{localizedTexts.labelSearch}: </span>&nbsp;&nbsp;<input placeholder={"suchen"} onChange={this.handleChange}/>
                </div>
                <div className={"col-4 d-flex align-items-center"}>

                    {
                        this.state.resultSheetDefinitions.map(rs => {
                            return <div>
                                <button key={"labels"+rs.id} className={"btn btn-primary"} style={{marginTop: '4px', marginBottom: '4px'}}
                                        onClick={() => {
                                            this.printOrganisationLabels(this.printValue(), rs.id, rs.isBarcodeSheet)
                                        }}><span className={"bi-printer"}/> {rs.name}</button>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className={"row"}>
                <table className={"table table-striped table-responsive"}>
                    <thead>
                    <tr>
                        <th><span className={"bi-printer"}/></th>
                        <th>{localizedTexts.headerBarcode}</th>
                        <th>{localizedTexts.headerLastName}</th>
                        <th>{localizedTexts.headerFirstName}</th>
                        <th>{localizedTexts.headerCity}</th>
                        <th>{localizedTexts.headerOrganization}</th>
                        {/* <th>{localizedTexts.headerActions}</th> */}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.availableParticipants
                            .filter(participant => participant.organization?.number === this.state.selectedOrganizationNumber
                                && (participant.person?.lastName?.toLowerCase().includes(this.state.search)
                                    || participant.person?.firstName?.toLowerCase().includes(this.state.search)
                                    || participant.person?.licenseNumber?.toLowerCase().includes(this.state.search)))
                            .sort((a, b): number => {
                                if (a.person?.firstName == null || b.person?.lastName == null) {
                                    return 0;
                                }
                                return a.person?.lastName?.localeCompare(b.person?.lastName) as number;
                            })
                            .map((p) => {
                                return <tr key={p.participantNumber}>
                                    <td><input type={"checkbox"} disabled={false} className={"form-check-input"} onClick={() => this.handlePrintList(p)}/></td>
                                    <td>{p.participantNumber}</td>
                                    <td>{p.person?.lastName}</td>
                                    <td>{p.person?.firstName}</td>
                                    <td>{p.person?.city}</td>
                                    <td>{p.organization?.name}</td>
                                </tr>
                            })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    }
}