import {useParams} from "react-router";
import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/RangeOverview.Text";
import ApiClientFactory from "./models/ApiClientFactory";
import {ShootingRange, TargetState} from "./models/Models";


function RangeOverviewWrapper() {
    let {rangeId} = useParams();
    let {distanceId} = useParams();
    return <RangeOverview rangeId={rangeId} distanceId={parseInt(distanceId!)}/>
}


export class RangeOverview extends Component<RangeOverviewStateProps, RangeOverviewState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: RangeOverviewStateProps) {
        super(props);
        this.state = {
            shootingRange: undefined,
            targets: [],
            isLoading: true,
            masterChecked: false,
            cmdActiveCheckBox: [],
            lastUpdated: ""
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            var targetResult = await this.apiClient.api.corePublicRangeDataTargetStateDetail(`${this.props.rangeId}`, this.props.distanceId);
            let range = await this.apiClient.api.coreShootingRangesDetail(`${this.props.rangeId}`);
            if (targetResult.ok && range.ok) {

                this.setState({targets: targetResult.data.targetStates || [], shootingRange: range.data, lastUpdated: targetResult.data.lastUpdate ?? "n/a"});
                this.setState(
                    {
                            cmdActiveCheckBox: (targetResult.data.targetStates!).map((target) =>
                                {
                                    return {laneNr: target.nr ?? 1, checked: false}
                                }
                            ), isLoading: false
                        });
            }

        } catch (e: any) {

        }
    }

    handleMasterChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        let items = [...this.state.cmdActiveCheckBox].map(item => ({...item, checked: e.target.checked}));
        this.setState({ masterChecked: e.target.checked, cmdActiveCheckBox: items });
    };

    handleSingleCheck = (e : React.ChangeEvent<HTMLInputElement>, targetNr : number) => {
        let index = targetNr - 1;
        let items = [...this.state.cmdActiveCheckBox];
        items[index].checked = e.target.checked;
        this.setState({ cmdActiveCheckBox: items });
        if (!items[index].checked) {
            this.setState({masterChecked : false});
        }
    };

    sendClearLog = async (rangeId : string, distanceId : number)=>     {

        try {
            let laneList = this.getLaneList();
            var result = await this.apiClient.api.coreConnectorManagementClearLogCreate(rangeId ?? "", distanceId, laneList);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on sending ClearLog");
        }
    }

    startMatch = async (rangeId : string, distanceId : number)=>     {

        try {
            let laneList = this.getLaneList();
            var result = await this.apiClient.api.corePublicRangeDataStartMatchCreate(rangeId ?? "", distanceId, laneList);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on sending Start Match");
        }
    }

    private getLaneList = () : number[]  => {
        if (this.state.masterChecked)
        {
            return [];
        }
        return this.state.cmdActiveCheckBox.filter((checkbox: any) => checkbox.checked).map((checkbox: any) => checkbox.laneNr)
    };

    requestDemoShot = async (rangeId : string, distanceId : number)=>     {

        try {
            let laneList = this.getLaneList();
            var result = await this.apiClient.api.corePublicRangeDataRequestDemoShotCreate(rangeId ?? "", distanceId, laneList);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on requesting demo shot");
        }
    }

    sendAbort = async (rangeId : string, distanceId : number)=>     {

        try {
            let laneList = this.getLaneList();
            var result = await this.apiClient.api.corePublicRangeDataRequestAbortCreate(rangeId ?? "", distanceId, laneList);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on sending abort");
        }
    }

    private updateCheckBox = (newValue: boolean, attribute: string, targetState: TargetState) => {
        var targetStatus = this.state.targets;
        var target: any = targetStatus?.find(s => s.nr === targetState.nr);
        if (target){
            target[attribute] = newValue;
        }

        this.setState({targets: targetStatus})
    }

    private isManualRange = (): boolean =>
    {
        var currentDiscipline = this.state.shootingRange?.shootingRangeDisciplines?.find(discipline => discipline.distanceId == this.props.distanceId);
        if (currentDiscipline?.shootingRangeTypeId === 1 || currentDiscipline?.shootingRangeTypeId === 2)
        {
            return true;
        }
        return false;
    }

    private isLonRange = (): boolean =>
    {
        var currentDiscipline = this.state.shootingRange?.shootingRangeDisciplines?.find(discipline => discipline.distanceId == this.props.distanceId);
        if (currentDiscipline?.shootingRangeTypeId === 6 || currentDiscipline?.shootingRangeTypeId === 7 )
        {
            return true;
        }
        return false;
    }

    private convertFireKind = (fireKind: number): string =>
    {
        if (fireKind === 1)
        {
            return localizedTexts.practiceShortName ?? "";
        }
        else if (fireKind === 2)
        {
            return localizedTexts.shotByShotShortName ?? "";
        }
        else if (fireKind === 3)
        {
            return localizedTexts.seriesShortName ?? "";
        }
        return ""
    }

    render()      {
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

        return <div className={"container-fluid"}>
            <h1>{localizedTexts.title} {this.state.shootingRange?.name} {this.props.distanceId === 1 ? "300m" : this.props.distanceId === 2 ? "50m" : this.props.distanceId === 3 ? "25m" : "10m"}</h1>
            <button className={"btn btn-primary"}
                    onClick={() => {
                        let answer = window.confirm(localizedTexts.clearLogQuestion)
                        if (answer) {
                            this.sendClearLog(this.props.rangeId ?? "", this.props.distanceId ?? "")
                        }}
                    }
                    hidden={!this.isLonRange()}>Clear Log
            </button>
            &nbsp;
            <button className={"btn btn-primary"}
                    onClick={() => {
                        let answer = window.confirm(localizedTexts.startMatchQuestion)
                        if (answer) {
                            this.startMatch(this.props.rangeId ?? "", this.props.distanceId ?? "")
                        }}
                    }
                    hidden={this.isManualRange()}>Start Match
            </button>
            &nbsp;
            <button className={"btn btn-primary"}
                    onClick={() => this.requestDemoShot(this.props.rangeId ?? "", this.props.distanceId ?? "")}
                    hidden={this.isManualRange()}>Demo Shot
            </button>
            &nbsp;
            <button className={"btn btn-primary"}
                    onClick={() => this.sendAbort(this.props.rangeId ?? "", this.props.distanceId ?? "")}
                    hidden={this.isManualRange()}>{localizedTexts.sendAbort}
            </button>

            <div className={"card mt-3"} hidden={!this.isLonRange()}>
                <div className={"card-body"}>
                    <h3 className={"card-title"}>{localizedTexts.targets}</h3>
                    Letztes Update: {new Date(this.state.lastUpdated).toLocaleString('de-CH')}
                    <table className={"table table-striped table-responsive"}>
                        <thead>
                        <tr>
                            <th className={"col-sm-1"}>{"Nr"}</th>
                            {this.isManualRange() ? "" :
                                <th className={"col-sm-1"}>{"Aktiv für Kommando"} <input type={"checkbox"} id={`targetAll`} value="active" checked={this.state.masterChecked} onChange={this.handleMasterChange}/> Alle </th>}
                            {this.isManualRange() ? "" : <th className={"col-sm-1"}>{"MemberOnly"}</th>}
                            <th>{"Status"}</th>
                            <th>{"SchützenNr"}</th>
                            <th>{"ProgramNr"}</th>
                            <th>{"SchussNr"}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.targets.map((p) => {
                                return <tr key={p.nr}>
                                    <td>{p.nr}</td>
                                    {this.isManualRange() ? "" :
                                        <td><input type={"checkbox"} id={`target${p.nr ?? 1}`} value="active" checked={this.state.cmdActiveCheckBox[p.nr? p.nr - 1 : 0].checked} onChange={ (e) => this.handleSingleCheck(e, p.nr ?? 1)}/></td>}
                                    {this.isManualRange() ? "" :
                                        <td>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input"  type="checkbox" id={`target${p.nr}member`} checked={p.isMemberOnly}
                                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => this.updateCheckBox(evt.currentTarget.checked, "isMemberOnly", p)}/>
                                            </div>
                                        </td>
                                    }
                                    <td>{p.status}</td>
                                    <td>{p.shooterNr}</td>
                                    <td>{p.programNr}</td>
                                    <td>{this.convertFireKind(p.fireKind ?? 0)} {p.shotNr}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

}

interface RangeOverviewState {
    isLoading: boolean;
    targets: TargetState[];
    lastUpdated: string
    shootingRange: ShootingRange | undefined
    masterChecked: boolean
    cmdActiveCheckBox: { laneNr: number, checked: boolean }[]
}

interface RangeOverviewStateProps {
    rangeId: string | undefined
    distanceId: number
}

export default RangeOverviewWrapper;