import {Api} from "./Models";

export default class ApiClientFactory{
    static GetApiClient(){
        return new Api({
            baseUrl: process.env.REACT_APP_API_LOCATION,
            baseApiParams: {
                credentials: "include",
                mode: "cors"
            }
        });
    }
}