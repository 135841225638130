import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "title" : "Vereinsübersicht",
        "labelOrganization":"Verein",
        "labelSearch":"Suchen",
        "labelLabels":"Standblätter",
        "textOrganizationSelect":"Verein auswählen...",
        "headerBarcode":"Schützennummer",
        "headerFirstName":"Vorname",
        "headerLastName":"Nachname",
        "headerCity":"Ort",
        "headerOrganization":"Verein",
        "headerActions":"Aktionen",
        "shooteroffsetmessage":"Wieviele Schützen wurden bereits auf dieser Seite gedruckt?"
    },
    "fr": {
        "overview" : "Aperçu",
        "title" : "Aperçu de l'association",
    },
    "it": {
        "overview" : "Panoramica",
        "title" : "Panoramica dell'associazione",
    },
    "en": {
        "overview" : "Overview",
        "title" : "Organization Overview",
    }
    }
)
export default localizedTexts;