import {Component} from "react";
import localizedTexts from "./texts/Overview.texts";
import ApiClientFactory from "./models/ApiClientFactory";
import {Event, EventType} from "./models/Models";
import UserProfileHandler from "../common/UserProfileHandler";
import {UserData} from "../core/models/Models";
import NewEventModal from "./NewEventModal";
import {NavLink} from "react-router-dom";


export default class EventOverview extends Component<EventOverviewProps, EventOverviewState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventOverviewProps) {
        super(props);
        this.state = {
            events: [],
            userData: undefined,
            eventTypes: []
        }
    }

    componentDidMount() {
        this.loadUserData();
        this.loadData();
    }

    async loadData() {
        try {
            var eventsResponse = await this.apiClient.api.eventsEventsList();
            var eventTypeResponse = await this.apiClient.api.eventsEventsEventtypesList();
            if (eventsResponse.ok && eventTypeResponse.ok) {
                this.setState({events: eventsResponse.data, eventTypes: eventTypeResponse.data});
            }

        } catch (e: any) {

        }
    }

    async loadUserData() {
        try {
            var userData = await UserProfileHandler.GetUserData();
            this.setState({userData: userData});

        } catch (e: any) {

        }
    }

    getNewEventButton(){
        if (this.state.userData && this.state.userData.claims &&  this.state.userData.claims.findIndex((c) => c.key == "roles" && c.value == "WinFireSuperAdmin") > -1){
            return <button className={"btn btn-primary"} type={"button"} data-bs-toggle="modal" data-bs-target="#newEventModal">{localizedTexts.newEvent}</button>
        }
    }

    render(){
        return <div>
            <h1>{localizedTexts.overview}</h1>
            {this.getNewEventButton()}
            <table className={"table table-responsive table-striped"}>
                <thead>
                <tr>
                    <th>{localizedTexts.headerName}</th>
                    <th>{localizedTexts.headerType}</th>
                    <th>{localizedTexts.headerActions}</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.events.map((event) => {
                        return <tr key={`event-${event.id}`}>
                            <td>{event.name}</td>
                            <td>{event.eventTyp}</td>
                            <td><NavLink className={"btn btn-primary"} to={`/event/${event.id}/overview`}>{localizedTexts.toEvent}</NavLink></td>
                        </tr>
                    })
                }
                </tbody>
            </table>
            <NewEventModal modalId={"newEventModal"} eventTypes={this.state.eventTypes} apiClient={this.apiClient} afterSaveUpdate={() => this.loadData()} />
        </div>
    }
}

interface EventOverviewState{
    events: Event[];
    userData: UserData|undefined;
    eventTypes: EventType[];
}

interface EventOverviewProps{

}