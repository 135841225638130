import {useParams} from "react-router";
import {Component} from "react";
import localizedTexts from "./texts/EventManagementOverview.texts";

function EventManagementOverviewWrapper(){
    let {eventId} = useParams();
    return <EventManagementOverview eventId={eventId} />
}

export class EventManagementOverview extends Component<EventManagementOverviewProps, EventManagementOverviewState>{

    constructor(props: EventManagementOverviewProps) {
        super(props);
        this.state = {

        }
    }

    render(){
        return <div>
            <h1>{localizedTexts.title}</h1>
        </div>
    }
}

interface EventManagementOverviewState{

}

interface EventManagementOverviewProps{
    eventId: string|undefined;
}

export default EventManagementOverviewWrapper;