import React, {Component} from "react";
import localizedTexts from "../../event/texts/EventParticipantsRegistration.texts";


export default class WinFireDropDown extends Component<WinFireDropDownProps, WinFireDropDownState>{
    private dropdownRef: React.RefObject<HTMLDivElement>;

    constructor(props: WinFireDropDownProps) {
        super(props);
        this.state = {
            showDropDown: false,
            filterText: ""
        }

        this.dropdownRef = React.createRef();
        this.handleClickAway = this.handleClickAway.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickAway);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickAway);
    }

    handleClickAway(event: MouseEvent) {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {

            this.setState({ showDropDown: false });
        }
    }

    render() {
        return <div className="dropdown" ref={this.dropdownRef}>
                <button className="btn btn-primary"
                    onClick={() => this.setState({showDropDown: !this.state.showDropDown})}>{this.props.buttonText}
                <span className={"bi-caret-down"}/></button>
                <div className={this.state.showDropDown ? "dropdown-content dropdown-show" : "dropdown-content"}>
                    <div className="input-group">
                        <input className="form-control border-end-0 border" type="text"
                               id="example-search-input" onChange={(evt) => {this.setState({filterText: evt.currentTarget.value})}}
                               value={this.state.filterText} autoComplete={"off"}/>
                        <span className="input-group-append">
                                                <button
                                                    className="btn btn-outline-secondary bg-white border-start-0 border ms-n3"
                                                    type="button">
                                                    <i className="bi bi-search"></i>
                                                </button>
                                            </span>
                    </div>
                    {
                        this.props.elements.filter(c => this.props.elementFilter(this.state.filterText, c.sourceObject)).map((c) => {
                            return <a key={c.key}
                                      onClick={() => {this.setState({showDropDown: false}); c.selectFunction(c.sourceObject)}}>{c.text}</a>
                        })
                    }
                </div>
        </div>
    }
}

interface WinFireDropDownState{
    showDropDown: boolean;
    filterText: string;
}

export interface WinFireDropDownElement{
    key: string;
    selectFunction: Function;
    text: string;
    sourceObject: object;
}

interface WinFireDropDownProps{
    buttonText: string;
    elements: WinFireDropDownElement[];
    elementFilter: Function;
}