import * as React from 'react';
import {UserDto} from "./models/UserDto";
import winFireFetcher from "../../components/WinFireFetcher";
import {Api, UserData} from "../core/models/Models";
import UserProfileHandler from "./UserProfileHandler";

export default class UserProfile extends React.Component<UserProfileProps, UserProfileState>{

    constructor(props:UserProfileProps) {
        super(props);
        this.state = {
            user: {
                name: "",
                isAuthenticated: false,
                claims:[],
            }
        }
    }

    componentDidMount() {
        this.loadUserData();
    }

    async loadUserData()  {
        try {
            let result = await UserProfileHandler.GetUserData();
            if (result) {
                this.setState({user: result});
            }
        } catch (e: any) {

        }
    }

    render(){
        return <div className={"container-fluid mt-2"}>
            <a className={"btn btn-winfire"} href={`${process.env.REACT_APP_API_LOCATION}/auth/login-callback?logout=${process.env.REACT_APP_API_LOCATION}/api/public/core/logout`}>Logout</a>
            <table className="table table-striped table-responsive">
                <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.user.claims?.map((c, idx) => {
                        return <tr key={`${c.key}${idx}`}>
                            <td>{c.key}</td>
                            <td>{c.value}</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}

export interface UserProfileProps {

}

export interface  UserProfileState {
    user: UserData;
}