import localizedTexts from "./texts/RangeAdministrationEditRangeModal.Text";
import React, {ChangeEvent} from "react";
import {Discipline, Distance, ShootingRange, ShootingRangeDiscipline, ShootingRangeType} from "./models/Models";

export default class RangeAdministrationEditRangeModal extends React.Component<RangeAdministrationNewRangeModalProps, RangeAdministrationNewRangeModalState> {

    constructor(props: RangeAdministrationNewRangeModalProps) {
        super(props);
        this.state = {
            name: props.shootingRange?.name ?? "",
            street: props.shootingRange?.street ?? "",
            city: props.shootingRange?.city ?? "",
            description: props.shootingRange?.description ?? "",
            zipCode: props.shootingRange?.zipCode ?? "",
            id: props.shootingRange?.id ?? "",
            shootingRangeDisciplines: props.shootingRange?.shootingRangeDisciplines ?? [{
                shootingRangeTypeId: 1,
                disciplineId: 1,
                laneCount: 5
            }],
            isSaving: false
        }
    }

    changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let stateHelper: any = this.state;
        stateHelper[evt.currentTarget.id] = evt.currentTarget.value;
        this.setState(stateHelper);
    }

    async saveHandler() {
        this.setState({isSaving: true});
        let shootingRange: ShootingRange = this.state;
        await this.props.saveHandler(shootingRange);

        this.setState({isSaving: false});
    }

    addDistanceHandler() {
        let srd = this.state.shootingRangeDisciplines;
        srd.push({laneCount: 1, shootingRangeTypeId: 1, disciplineId: 1});
        this.setState({shootingRangeDisciplines: srd})
    }

    laneCountChangehandler = (evt: ChangeEvent<HTMLInputElement>, idx: number) => {
        let srd = this.state.shootingRangeDisciplines;
        srd[idx].laneCount = evt.currentTarget.valueAsNumber;
        this.setState({shootingRangeDisciplines: srd});
    }

    laneStartChangehandler = (evt: ChangeEvent<HTMLInputElement>, idx: number) => {
        let srd = this.state.shootingRangeDisciplines;
        srd[idx].startLaneNumber = evt.currentTarget.valueAsNumber;
        this.setState({shootingRangeDisciplines: srd});
    }

    laneEndChangehandler = (evt: ChangeEvent<HTMLInputElement>, idx: number) => {
        let srd = this.state.shootingRangeDisciplines;
        srd[idx].endLaneNumber = evt.currentTarget.valueAsNumber;
        this.setState({shootingRangeDisciplines: srd});
    }

    selectChangehandler = (evt: ChangeEvent<HTMLSelectElement>) => {
        let srd: any[] = this.state.shootingRangeDisciplines;
        srd[parseInt(evt.currentTarget.id)][evt.currentTarget.name] = parseInt(evt.currentTarget.value);
        this.setState({shootingRangeDisciplines: srd});
    }

    render() {
        return <div className="modal fade modal-xl" id={this.props.modalId} tabIndex={-1}
                    aria-labelledby={this.props.modalId}
                    aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={this.props.modalId+"Label"}>{localizedTexts.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="id"
                                               placeholder={localizedTexts.labelId}
                                               value={this.state.id} onChange={this.changeHandler}/>
                                        <label htmlFor="id">{localizedTexts.labelId}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="name"
                                               placeholder={localizedTexts.labelName}
                                               value={this.state.name} onChange={this.changeHandler}/>
                                        <label htmlFor="name">{localizedTexts.labelName}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="description"
                                               placeholder={localizedTexts.labelDescription}
                                               value={this.state.description} onChange={this.changeHandler}/>
                                        <label htmlFor="description">{localizedTexts.labelDescription}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="street"
                                               placeholder={localizedTexts.labelStreet}
                                               value={this.state.street} onChange={this.changeHandler}/>
                                        <label htmlFor="street">{localizedTexts.labelStreet}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-3"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="zipCode"
                                               placeholder={localizedTexts.labelZipCode}
                                               value={this.state.zipCode} onChange={this.changeHandler}/>
                                        <label htmlFor="zipCode">{localizedTexts.labelZipCode}</label>
                                    </div>
                                </div>
                                <div className={"col"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="city"
                                               placeholder={localizedTexts.labelCity}
                                               value={this.state.city} onChange={this.changeHandler}/>
                                        <label htmlFor="city">{localizedTexts.labelCity}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"shadow p-2 rounded"}>
                            <h2>{localizedTexts.labelDisciplines}</h2>
                            <table className={"table table-responsive"}>
                                <thead>
                                <tr>
                                    <th>{localizedTexts.headerDisciplineName}</th>
                                    <th>{localizedTexts.headerLaneCount}</th>
                                    <th>{localizedTexts.headerStartLaneNumber}</th>
                                    <th>{localizedTexts.headerEndLaneNumber}</th>
                                    <th>{localizedTexts.headerRangeType}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.shootingRangeDisciplines.map((srd, idx) => {
                                        return <tr key={`distance${idx}`}>
                                            <td><select id={`${idx}`} name={"disciplineId"} className={"form-select"}
                                                        onChange={this.selectChangehandler}>
                                                {
                                                    this.props.disciplines.map((d) => {
                                                        return <option value={d.id}
                                                                       selected={d.id == srd.disciplineId}>{d.name}</option>
                                                    })
                                                }
                                            </select></td>
                                            <td><input id={`laneCount${idx}`} type={"number"} className={"form-control"}
                                                       min={1}
                                                       max={999} value={srd.laneCount}
                                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                           this.laneCountChangehandler(evt, idx)
                                                       }}/></td>
                                            <td><input id={`laneStart${idx}`} type={"number"} className={"form-control"}
                                                       min={1}
                                                       max={999} value={srd.startLaneNumber}
                                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                           this.laneStartChangehandler(evt, idx)
                                                       }}/></td>
                                            <td><input id={`laneEnd${idx}`} type={"number"} className={"form-control"}
                                                       min={1}
                                                       max={999} value={srd.endLaneNumber}
                                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                           this.laneEndChangehandler(evt, idx)
                                                       }}/></td>
                                            <td><select id={`${idx}`} name={"shootingRangeTypeId"}
                                                        className={"form-select"} onChange={this.selectChangehandler}>
                                                {
                                                    this.props.rangeTypes.map((rt) => {
                                                        return <option value={rt.id}
                                                                       selected={rt.id == srd.shootingRangeTypeId}>{rt.name}</option>
                                                    })
                                                }
                                            </select></td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                            <button type={"button"} className={"btn btn-primary"}
                                    onClick={() => this.addDistanceHandler()}>{localizedTexts.labelAddDiscipline}</button>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                data-bs-dismiss="modal">{localizedTexts.labelCancel}</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {
                            this.saveHandler()
                        }}>{this.state.isSaving ? <span
                            className="spinner-border spinner-border-sm"/> : ""}{localizedTexts.labelSave}</button>
                    </div>
                </div>
            </div>
        </div>
    }
}

interface RangeAdministrationNewRangeModalProps {
    modalId: string;
    shootingRange?: ShootingRange;
    saveHandler: Function;
    disciplines: Discipline[];
    rangeTypes: ShootingRangeType[];
}

interface RangeAdministrationNewRangeModalState {
    id: string;
    name: string;
    description: string;
    street: string;
    zipCode: string;
    city: string;
    shootingRangeDisciplines: ShootingRangeDiscipline[]
    isSaving: boolean;
}
