import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Gruppe",
        "labelName":"Gruppenname",
        "labelNumber":"Gruppennummer",
        "labelNewGroup":"Gruppe",
        "labelExistingGroup":"Bestehende Gruppen",
        "textSelectOrganizationFirst":"Bitte zuerst den Schützen und den Verein auswählen",
        "labelSave":"Speichern",
        "labelRemove":"Gruppe entfernen"
    },
    "fr": {
        "title" : "Group",
        "labelName":"Nom du groupe",
        "labelNumber":"No. du groupe",
        "labelNewGroup":"Group",
        "labelExistingGroup":"Groupes existants",
        "textSelectOrganizationFirst":"Veuillez d'abord choisir le tireur et l' association",
        "labelSave":"Enregistrer",
        "labelRemove":"Supprimer le groupe"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;