import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "shootingranges": "Schiessanlagen",
        "shootingprograms" : "Schiessprogramme",
        "settings": "Einstellungen"
    },
    "fr": {
        "overview" : "Aperçu",
        "shootingranges": "Stands de tir",
        "shootingprograms" : "exercises",
        "settings" : "paramètres"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;