import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Runden",
        "headerBarcode":"Schützennummer",
        "headerFirstName":"Vorname",
        "headerLastName":"Nachname",
        "headerCity":"Ort",
        "headerOrganization":"Verein",
        "headerActions":"Aktionen",
        "textSiteLoading": "Seite lädt. Bitte einen moment Geduld haben.",
        "testStartRoundConfirmation" : "Wollen Sie die Ablösung {nr} starten?",
        "labelNewRound" : "Ablösung hinzufügen",
        "labelImport": "Import"
    },
    "fr": {
        "title" : "Rondes",
        "headerBarcode":"No. du tireur",
        "headerFirstName":"Prénom",
        "headerLastName":"Nom de famille",
        "headerCity":"Lieu",
        "headerOrganization":"Association",
        "headerActions":"Actions",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant.",
        "testStartRoundConfirmation" : "Veulliez commencer avec ronde {nr}?",
        "labelNewRound" : "ajouter une rounde",
        "labelImport": "Importer"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;