import {useParams} from "react-router";
import React, {ChangeEvent, Component} from "react";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventParticipantsResultByShootingProgram.texts";
import {
    EventRegistratioInformation,
    Exercise,
    ParticipantWithResults,
    ShootingProgramDefinition, ShootingProgramSequence,
    Weapon
} from "./models/Models";

function EventParticipantsResultByShootingProgramWrapper(){
    let {eventId, participantnumber} = useParams();
    return <EventParticipantsResultByShootingProgram eventId={eventId} participantNumber={participantnumber} />
}

export class EventParticipantsResultByShootingProgram extends Component<EventParticipantsResultByShootingProgramProps, EventParticipantsResultByShootingProgramState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventParticipantsResultByShootingProgramProps) {
        super(props);

        this.state = {
            currentView: View.ProgramSelection,
            shooterNumber: "",
            personNumber: "",
            licenseNumber: "",
            selectedExerciseId: undefined,
            allWeapons: [],
            isLoading: true,
            shootingProgramDefinitions: {},
            participant: undefined,
            eventRegistrationInformation: undefined,
            shootingProgramDefinitionList: [],
            selectedShootingProgram: undefined
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData(){
        try {
            var infos = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            if (infos.ok) {
                var weapons: any = {};
                var shootingProgramDefinitions: any = {};
                var shootingProgramDefinitionList: ShootingProgramDefinition[] = [];
                if (infos.data.disciplines?.length && infos.data.disciplines?.length > 0) {
                    infos.data.disciplines.forEach(d => {
                        d.shootingProgramDefinitions?.forEach(s => {
                            shootingProgramDefinitionList.push(s);
                            var shootingProgramDefinitionId = `${s.id}`;
                            shootingProgramDefinitions[shootingProgramDefinitionId] = s;
                        })
                        d.fields?.forEach(f => {
                            f.weapons?.forEach(w => {
                                var weaponId = `${w.id}`;
                                weapons[weaponId] = w;
                            })
                        })
                    });
                }

                this.setState({
                    eventRegistrationInformation: infos.data,
                    allWeapons: weapons,
                    shootingProgramDefinitions: shootingProgramDefinitions,
                    shootingProgramDefinitionList: shootingProgramDefinitionList,
                    isLoading: false
                });
            }
        } catch (e: any) {

        }
    }

    handleSearchFieldChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        var newValue = evt.currentTarget.value;
        if (newValue.length < 10 || !this.getParticipant(evt.currentTarget.value)){
            this.setState({participant: {
                    participantNumber: newValue
                }})
        }
    }

    getParticipant = async (participantNumber: string):Promise<boolean> => {

        var result = undefined;
        try {
            if (participantNumber.length == 10) {
                result = await this.apiClient.api.eventsParticipantsWithResultsDetail(participantNumber, this.props.eventId ?? "");
            }
        } catch (e) {
        }
        if (result && result.ok){
            this.setState({participant: result.data});
        }else{
            return false;
        }

        return true;
    }

    handleNextProgram = () => {
        this.setState({
            currentView: View.ProgramSelection,
            shooterNumber: "",
            personNumber: "",
            licenseNumber: "",
            selectedExerciseId: undefined,
            participant: undefined,
            selectedShootingProgram: undefined
        });
        document.getElementById("shooternumber")!.focus();
    }

    handleSaveButton = async () => {
        if (this.state.participant) {
            var participant = this.state.participant;
            participant.person?.organizations?.forEach(o => o.discipline = undefined);
            try {
                var saveResult = await this.apiClient.api.eventsParticipantsUpdateresultsUpdate(this.props.eventId ?? "", participant)
                if (saveResult.ok) {
                    this.setState({
                        shooterNumber: "",
                        personNumber: "",
                        licenseNumber: "",
                        selectedExerciseId: undefined,
                        participant: undefined,
                    });
                    document.getElementById("shooternumber")!.focus();
                }
            } catch (e: any) {

            }
        }
    }

    render() {
        let view = undefined;
        switch (this.state.currentView) {
            case View.ResultEntry:
                view = this.getResultEntryView();
                break;
            case View.ProgramSelection:
                view = this.getProgramSelectionView();
                break;
            default:
                view = this.getProgramSelectionView();
                break;
        }
        return <div>
            <div className={"row p-3 g-3"}>
                <div className={"col"}>
                    <h1>{localizedTexts.title}</h1>
                </div>
            </div>
            {view}
            {this.getShotEntryModal()}
        </div>
    }

    getProgramSelectionView(): JSX.Element {
        return <div>
            <h3>Stich auswählen:</h3>
            {
                this.state.shootingProgramDefinitionList.filter(spd => !spd.isCalculated).sort((a, b) => a.visualOrder! - b.visualOrder!).map((sp:any) => {
                    return <div key={sp.id} className={"row"}>
                        <div className={"col-md-2"}>
                            <button style={{width: '100%'}} className={"btn btn-outline-primary"} onClick={() => {
                                this.setState({
                                    selectedShootingProgram: sp,
                                    currentView: View.ResultEntry})
                            }}>{sp.name}</button>
                        </div>
                    </div>
                })
            }
        </div>
    }

    getResultEntryView() {
        return <div>
            <div className={"row p-3 g-3 shadow bg-body rounded"}>
                <div className={"col-md-6"}>
                    {this.getShooterInfo()}
                </div>
                <div className={"col-md-6"}>
                    {this.getShootingPrograms()}
                </div>
            </div>
            <div className={"row p-3 g-3 shadow bg-body rounded"}>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-dark registrationButton"}
                            onClick={() => this.handleSaveButton()}>{localizedTexts.buttonSaveAndNext}
                    </button>
                </div>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-primary registrationButton"}
                            onClick={() => this.handleNextProgram()}>{localizedTexts.buttonNextProgram}
                    </button>
                </div>
            </div>
        </div>
    }

    getShooterInfo() {
        return <div>
            <div className={"row"}>
                <div className="form-group col-md-6">
                    <label htmlFor="shooternumber">{localizedTexts.labelBarcode}</label>
                    <input type="text" value={this.state.participant?.participantNumber ?? ""} name="shooternumber"
                           onChange={this.handleSearchFieldChange} className="form-control"
                           id="shooternumber" placeholder="" aria-describedby="shooternumber"
                           autoFocus={true}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <h3>{localizedTexts.labelShooter}</h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelPersonNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.personNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLicenseNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.licenseNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLastName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.lastName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelFirstName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.firstName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelCity}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.person?.city}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelBirthdate}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.person?.birthday}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelOrganization}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.organization?.name} {this.state.participant?.organization?.city}</span>
                </div>
            </div>
        </div>
    }

    getShootingPrograms() {
        return <div>
            <table className={"table table-striped"}>
                <tbody>
                {
                    this.state.participant?.exercises
                        ?.filter(s => s.shootingProgramDefinitionId == this.state.selectedShootingProgram?.id)
                        ?.sort((a, b) => this.state.shootingProgramDefinitions[`${a.shootingProgramDefinitionId}`]?.visualOrder! - this.state.shootingProgramDefinitions[`${b.shootingProgramDefinitionId}`]?.visualOrder!)
                        .map((e) => {
                            var shootingProgramDefinition: ShootingProgramDefinition = this.state.shootingProgramDefinitions[`${e.shootingProgramDefinitionId}`];
                            var maxResult = 0;
                            shootingProgramDefinition.shootingProgramSequences?.forEach(s => {
                                if(s.firekindId! > 19){
                                    maxResult += s.shotCount! * s.maxShotvalue!;
                                }
                            })

                            return <tr key={e.id}>
                                <td>
                                    {shootingProgramDefinition.name}
                                </td>
                                <td>
                                    {
                                        shootingProgramDefinition.shotsRequired
                                            ? <button className={"btn btn-outline-primary"} data-bs-toggle="modal"
                                                      data-bs-target="#enterShotsModal" onClick={() => {
                                                this.setState({selectedExerciseId: e.id});

                                            }}>{localizedTexts.buttonEnterResult}</button>
                                            : ""
                                    }
                                </td>
                                <td>
                                    {
                                        shootingProgramDefinition.isBestShotRequired ? <div><label>{localizedTexts.labelBestShot}:&nbsp;</label>
                                            <input
                                                type={"number"}
                                                disabled={shootingProgramDefinition.shotsRequired}
                                                value={e.bestShotValue ?? 0}
                                                className={"form-field"}
                                                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                    var participant = this.state.participant;
                                                    var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                                    if (evt.currentTarget.valueAsNumber <= 100){
                                                        toUpdateExercise.bestShotValue = evt.currentTarget.valueAsNumber;
                                                    }
                                                    this.setState({participant: participant});
                                                }}
                                            /></div> : <div />
                                    }
                                </td>
                                <td>
                                    <label>{localizedTexts.labelResult}:&nbsp;</label>
                                    <input
                                        type={"number"}
                                        disabled={shootingProgramDefinition.shotsRequired}
                                        value={e.result ?? 0}
                                        max={maxResult}
                                        className={"form-field"}
                                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                            var participant = this.state.participant;
                                            var toUpdateExercise = participant?.exercises?.find(pe => pe.id == e.id)!;
                                            var shotValue = evt.currentTarget.valueAsNumber;
                                            if (shotValue <= maxResult) {
                                                toUpdateExercise.result = evt.currentTarget.valueAsNumber;
                                            }
                                            this.setState({participant: participant});
                                        }}/>
                                </td>
                            </tr>
                        })
                }
                </tbody>
            </table>
        </div>
    }

    getShotEntryModal = () => {
        var exercise = this.state.participant?.exercises?.find(e => e.id == this.state.selectedExerciseId);
        return <div className="modal fade modal-xl" id="enterShotsModal" tabIndex={-1}
                    aria-labelledby="enterShotsModal"
                    aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={"enterShotsModalLabel"}>{localizedTexts.labelEnterShotsModalTitle}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className={"table table-striped table-hover table-responsive-sm"}>
                            <tbody>
                            {this.getShotRowsForModal(exercise)}
                            <tr>
                                <td colSpan={3}>
                                    <hr/>
                                </td>
                            </tr>
                            {this.getBestShotInput(exercise)}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary"
                                data-bs-dismiss="modal">{localizedTexts.buttonSaveModal}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    getBestShotInput(exercise: Exercise|undefined) {

        if (!exercise || !this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`].isBestShotRequired){
            return <tr></tr>
        }

        var participant = this.state.participant;
        if (participant) {
            return <tr>
                <td><label>{localizedTexts.labelBestShot}:&nbsp;</label></td>
                <td><input
                    type={"number"}
                    value={exercise.bestShotValue ?? 0}
                    min={0}
                    max={100}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;
                        if (evt.currentTarget.valueAsNumber <= 100) {
                            toUpdateExercise.bestShotValue = evt.currentTarget.valueAsNumber;
                        }
                        this.setState({participant: participant});
                    }}
                    className={"form-field"}/></td>
            </tr>
        }

        return <tr></tr>
    }

    getShotRowsForModal(exercise: Exercise | undefined) {
        if (!exercise) {
            return <div>
            </div>
        }
        var participant = this.state.participant;
        var shootingProgramSequences : ShootingProgramSequence[] = this.state.shootingProgramDefinitions[`${exercise.shootingProgramDefinitionId}`]!.shootingProgramSequences!.sort((a:ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!);

        if (participant) {
            var toUpdateExercise = participant!.exercises!.find(e => e.id == exercise.id)!;

            if (!exercise.shots){
                exercise.shots = [];
            }

            if (exercise.shots.length == 0){
                var currentDate = new Date();
                shootingProgramSequences.filter(sq => sq.firekindId! >= 20).sort(sps => sps.order!).forEach(sps => {
                    Array.from({length: sps.shotCount!}, (value, index) => index+1).forEach((s) => {
                        exercise.shots?.push({
                            shotNumber: s,
                            value: 0,
                            isIllegalShot: false,
                            isDemo: false,
                            isPractice: sps.firekindId == 10,
                            date: `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`,
                            time: `${currentDate.getHours().toString().padStart(2, "0")}:${currentDate.getMinutes().toString().padStart(2, "0")}:${currentDate.getSeconds().toString().padStart(2, "0")}`
                        });
                    })
                })
            }

            return exercise.shots?.filter(s => s.isPractice == false).map((s, idx) => {
                var firekindText = localizedTexts.labelTrainingShots;
                var firekindShotNumber = idx + 1;
                var valueSet = false;
                var maxShotValue = 10;
                shootingProgramSequences.filter(sq => sq.firekindId! >= 20).forEach((s: ShootingProgramSequence) => {

                    if (firekindShotNumber - s.shotCount! <= 0 && !valueSet) {
                        maxShotValue = s.maxShotvalue ?? 100;
                        switch (s.firekind) {
                            case "Sighters":
                                firekindText = localizedTexts.labelTrainingShots;
                                break;
                            case "ShotByShot":
                                firekindText = localizedTexts.labelSingleShots;
                                break;
                            case "Series":
                                firekindText = localizedTexts.labelSeriesShots;
                                break;
                        }
                        valueSet = true;
                    } else if (firekindShotNumber > s.shotCount! && !valueSet) {
                        firekindShotNumber = firekindShotNumber - s.shotCount!;
                    }
                });

                return <tr key={exercise.id + `${idx}`}>
                    <td>{firekindShotNumber}. {firekindText}:</td>
                    <td><input
                        autoFocus={idx == 0}
                        type={"number"}
                        value={s.value ?? 0}
                        min={0}
                        max={maxShotValue}//should be updated to proper value
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            if (maxShotValue >= evt.currentTarget.valueAsNumber) {
                                toUpdateExercise.shots!.filter(s => s.isPractice == false)[idx].value = evt.currentTarget.valueAsNumber;
                            }

                            var arrayIndex = 0;
                            var result: number = 0;
                            shootingProgramSequences.sort((a: ShootingProgramSequence, b: ShootingProgramSequence) => a.order! - b.order!).forEach((s: ShootingProgramSequence) => {
                                for (let i = 0; i < s.shotCount!; i++) {
                                    if (s.firekindId && s.firekindId >= 20) {
                                        result += toUpdateExercise.shots!.filter(s => s.isPractice == false)[arrayIndex].value ?? 0;
                                        ++arrayIndex;
                                    }
                                }
                            })
                            toUpdateExercise.result = result;
                            this.setState({participant: participant});
                        }}/></td>
                </tr>
            })
        }

        return <div></div>
    }
}

enum View {
    ProgramSelection,
    ResultEntry
}

export interface  EventParticipantsResultByShootingProgramState{
    currentView: View;
    eventRegistrationInformation: EventRegistratioInformation | undefined;
    participant: ParticipantWithResults|undefined;
    allWeapons: any;
    shootingProgramDefinitions: any;
    shootingProgramDefinitionList: ShootingProgramDefinition[];
    selectedExerciseId: number|undefined;
    isLoading: boolean;
    selectedShootingProgram: ShootingProgramDefinition | undefined;

    licenseNumber: string;
    personNumber: string;
    shooterNumber: string;
}

export interface EventParticipantsResultByShootingProgramProps{
    eventId: string|undefined;
    participantNumber: string|undefined;
}

export default EventParticipantsResultByShootingProgramWrapper;