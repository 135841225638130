import React, {ChangeEvent, Component} from "react";
import {ShootingRange} from "./models/Models";
import localizedTexts from "./texts/EventManagementShootingRangesAddRangeModal.text";
import {ShootingRange as FromCoreShootingRange} from "../core/models/Models";
import ApiClientFactory from "../core/models/ApiClientFactory";
import EventApiClientFactory from "./models/ApiClientFactory";
import * as querystring from "node:querystring";

export default class EventManagementShootingRangesAddRangeModal extends Component<EventManagementShootingRangesAddRangeModalProps, EventManagementShootingRangesAddRangeModalState> {
    apiClient = ApiClientFactory.GetApiClient();
    eventApiClient = EventApiClientFactory.GetApiClient();

    constructor(props: EventManagementShootingRangesAddRangeModalProps) {
        super(props);
        this.state = {
            name: "",
            coreRanges: undefined,
            isLoading: true,
            selectedRange: undefined
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            let range = await this.apiClient.api.coreShootingRangesList();
            if (range.ok) {
                this.setState({coreRanges: range.data || [], isLoading: false})
            }

        } catch (error) {

        }
    };

    handleCheckboxChange = (rangeId: string) => {

        let currentRange = rangeId;
        if (this.state.selectedRange === rangeId)
        {
            currentRange = "";
        }

        this.setState({ selectedRange: currentRange });
    };

    handleSave = async () => {
        // Handle save logic here
        let range = this.state.selectedRange;
        if (range === undefined)
        {
            return
        }

        console.log('Selected range:', range);

        const rangeNumber = range.split(";")[0];
        const rangeDistanceId = parseInt(range.split(";")[1]);

        var shootingRange = this.state.coreRanges?.find(range => range.id === rangeNumber) ?? undefined;
        if (shootingRange != undefined) {
            let result = await this.eventApiClient.api.eventsEventManagementShootingrangesAddCreate( rangeDistanceId, this.props.eventId ?? "", shootingRange);
            if (result.ok)
            {
                console.log('calling on Close',  this.props.onClose);
                this.props.onClose()
            }
        }
    };

    isAlreadyAdded = (rangeNumber: string, disciplineId: number)  => {
        let convertedId: number;
        switch (disciplineId) {
            case 1:
                convertedId = 10;
                break;
            case 2:
                convertedId = 11;
                break;
            case 3:
                convertedId = 12;
                break;
            case 4:
                convertedId = 20
                break;
            case 5:
                convertedId = 21;
                break;
            case 6:
                convertedId = 22;
                break;

            default:
                convertedId = 10;
                break;
        }

        let rangeExisting = this.props.rangesAtEvent.find(range => range.number === rangeNumber && range.disciplineId == convertedId) ?? undefined;
        return rangeExisting != undefined;
    }

    render() {
        if (this.state.isLoading) {
            return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1}
                        aria-labelledby="groupRegistration" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className={"spinner-border"} role={"status"}/>
                    </div>
                </div>
            </div>
        }

        return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1}
                    aria-labelledby="groupRegistration" aria-hidden="true">
            <div className="modal-dialog" role="document">

                <div className="modal-content">
                    <div className="modal-header">
                        <table className={"table table-striped table-responsive"}>
                            <thead>
                            <tr>
                                <th style={{width: "5%"}}>{"Auswahl"}</th>
                                <th style={{width: "10%"}}>{localizedTexts.labelNumber}</th>
                                <th>{localizedTexts.labelName}</th>
                                <th>{localizedTexts.labelDescription}</th>
                                <th style={{width: "10%", textAlign: "center"}}>{localizedTexts.labelDiscipline}</th>
                                <th>{localizedTexts.labelCity}</th>

                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.coreRanges?.map((r, rangeIndex) =>
                                    r.shootingRangeDisciplines?.map((d, disciplineIndex) => {
                                        return <tr key={`range-${rangeIndex}-discipline-${disciplineIndex}`}>
                                            <td style={{width: "5%", textAlign: "center", verticalAlign: "middle"}}>
                                                <input
                                                    type="radio"
                                                    disabled={this.isAlreadyAdded(r.id!, d.disciplineId!)}
                                                    onChange={() => this.handleCheckboxChange(r.id! + ";" +d.disciplineId || "")}
                                                    checked={this.state.selectedRange === r.id! + ";" + d.disciplineId}
                                                />
                                            </td>
                                            <td>
                                                {r.id}
                                            </td>
                                            <td>
                                                {r.name}
                                            </td>
                                            <td>
                                                {r.description}
                                            </td>
                                            <td style={{width: "10%", textAlign: "center", verticalAlign: "middle"}}>
                                                {d.disciplineName}
                                            </td>
                                            <td>
                                                {r.city}
                                            </td>
                                        </tr>
                                    })
                                )

                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{localizedTexts.labelAbort}</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={this.handleSave}>{localizedTexts.labelSave}</button>
                    </div>
                </div>
            </div>
        </div>

    }
}

interface EventManagementShootingRangesAddRangeModalState {
    name: string;
    coreRanges: FromCoreShootingRange[] | undefined;
    isLoading: boolean;
    selectedRange: string | undefined;
}

interface EventManagementShootingRangesAddRangeModalProps {
    eventId: string | undefined;
    modalId: string;
    rangesAtEvent: ShootingRange[]
    onClose: () => void;
}