import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "home":"Home",
        "core":"Core",
        "event":"Anlass",
    },
    "fr": {

    },
    "it": {

    },
    "en": {

    }
});

export default localizedTexts;