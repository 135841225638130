import {Component} from "react";
import localizedTexts from "./texts/CoreNavigation.Text";
import {Link, NavLink} from "react-router-dom";

function CoreNavigation() {
    return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
        <NavLink to={"overview"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.overview}
        </NavLink>
        <NavLink to={"ranges"} className="btn btn-outline-dark">
            <span className={"bi-bullseye"}> </span>{localizedTexts.ranges}
        </NavLink>
        {/*<NavLink to={"bar"} className="btn btn-outline-dark">
            <span className={"bi-person-lines-fill"}> </span>{localizedTexts.organizations}
        </NavLink>
        <NavLink to={"gugus"} className="btn btn-outline-dark">
            <span className={"bi-people-fill"}> </span>{localizedTexts.members}
        </NavLink>*/}
    </div>
}

export default CoreNavigation;