import React, {useEffect, useState} from "react";
import {
    Api,
    Discipline,
    Distance,
    ShootingRange,
    ShootingRangeDiscipline,
    ShootingRangeType,
    UserData
} from "./models/Models";
import localizedTexts from "./texts/RangeAdministration.Text";
import RangeAdministrationEditRangeModal from "./RangeAdministrationEditRangeModal";
import ApiClientFactory from "./models/ApiClientFactory";
import UserProfileHandler from "../common/UserProfileHandler";
import {NavLink} from "react-router-dom";

export default class RangeAdministration extends React.Component<any, State>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props:any) {
        super(props);
        this.state = {
            ranges: [],
            disciplines: [],
            rangeTypes: [],
            userData: undefined
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadUserData();
    }

    async loadData() {
        try {
            let disciplines = await this.apiClient.api.coreDisciplinesList();
            let ranges = await this.loadRanges(false);
            let rangeTypes = await this.apiClient.api.coreShootingRangeTypesList();
            if (disciplines.ok && ranges && rangeTypes.ok) {
                this.setState({disciplines: disciplines.data, ranges: ranges, rangeTypes: rangeTypes.data});
            }
        } catch (e: any) {

        }
    }

    async loadUserData(){
        var userData = await UserProfileHandler.GetUserData();
        this.setState({userData: userData});
    }

    async loadRanges(setState: boolean) {
        try {
            let ranges = await this.apiClient.api.coreShootingRangesList();
            if (ranges.ok) {
                if (setState) {
                    this.setState({ranges: ranges.data})
                }
                return ranges.data;
            }
        } catch (e: any) {

        }
    }

    newShootingRangeHandler = async (newShootingRange: ShootingRange):Promise<boolean> => {
        try {
            let result = await this.apiClient.api.coreShootingRangesCreate(newShootingRange);
            this.loadRanges(true);
            return result.ok;
        } catch (e: any) {
            return false;
        }
    }

    getNewRangeButton(){
        if (this.state.userData && this.state.userData.claims &&  this.state.userData.claims.findIndex((c) => c.key == "roles" && c.value == "WinFireSuperAdmin") > -1) {
            return <button type={"button"} className={"btn btn-primary"} data-bs-toggle="modal"
                           data-bs-target="#newRangeModal">{localizedTexts.labelNewRange}</button>
        }
    }

    render() {
        return <div>
            <h1>{localizedTexts.title}</h1>
            {this.getNewRangeButton()}
            <table className={"table table-responsive table-striped"}>
            <thead>
            <tr>
                <th>{localizedTexts.headerId}</th>
                <th>{localizedTexts.headerName}</th>
                <th>{localizedTexts.headerCity}</th>
                <th className={"d-none d-md-table-cell"}>{localizedTexts.headerComment}</th>
                <th className={"d-none d-md-table-cell"}>{localizedTexts.headerDiscipline}</th>
                <th></th>
                <th className={"d-none d-sm-table-cell"}>{localizedTexts.headerLastSeen}</th>
                <th>{localizedTexts.headerActions}</th>
            </tr>
            </thead>
            <tbody>
            {
                this.state.ranges.map((range) => {
                    return <tr key={range.id}>
                        <td>{range.id}</td>
                        <td>{range.name}</td>
                        <td>{range.city}</td>
                        <td className={"d-none d-md-table-cell"}>{range.description}</td>
                        <td className={"d-none d-md-table-cell"}><ul>{range.shootingRangeDisciplines?.map((d) =>{
                            return <li>{this.state.disciplines.find(discipline => discipline.id == d.disciplineId)?.name} : {d.laneCount} {localizedTexts.labelLanes} {this.state.rangeTypes.find(r => r.id == d.shootingRangeTypeId)?.name}
                                <div className={"mt-2 mb-2"}>
                                    <NavLink className={"btn btn-primary"} data-toggle="tooltip" data-placement="top" title="Test"
                                             to={`/core/ranges/${range.id}/${d.distanceId}/overview`}><span
                                        className={"bi-sliders"}> </span></NavLink>
                                </div>
                            </li>
                        })}</ul>
                        </td>
                        <td>

                        </td>
                        <td className={"d-none d-sm-table-cell"}><ul className={"list-unstyled"}>{range.shootingRangeDisciplines?.map((d) =>{
                            let now = new Date();
                            now.setMinutes(now.getMinutes() - 4)
                            let aliveTimestamp = d.aliveTimestamp ? new Date(d.aliveTimestamp.toString()) : null;
                            if (aliveTimestamp != null && aliveTimestamp > now )
                            {
                                return <li
                                    className={"text-success mb-5"}> <span className={"bi-check-circle-fill text-success"}></span> {d.aliveTimestamp ? new Date(d.aliveTimestamp).toLocaleString('de-CH') : ""} </li>
                            }
                            else {
                                return <li
                                    className={"text-danger mb-5"}>  <span className={"bi-x-circle-fill text-danger"}></span> {d.aliveTimestamp ? new Date(d.aliveTimestamp).toLocaleString('de-CH') : ""} </li>
                            }

                        })}</ul></td>
                        <td><button type={"button"} className={"btn btn-primary"}><i className={"bi bi-pencil-fill"}  /> </button> </td>
                    </tr>
                })
            }
            </tbody>
        </table>
        <RangeAdministrationEditRangeModal modalId={"newRangeModal"} saveHandler={this.newShootingRangeHandler} disciplines={this.state.disciplines} rangeTypes={this.state.rangeTypes} />
    </div>
}

}

interface State {
    ranges: ShootingRange[];
    disciplines: Discipline[];
    rangeTypes:ShootingRangeType[];
    userData: UserData|undefined;
}
