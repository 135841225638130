import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/EventParticipantsRegistrationGroupModal.text";
import {Group, ShootingProgramDefinition, Weapon} from "./models/Models";
import WinFireDropDown from "../common/components/WinFireDropDown";

export default class EventParticipantsRegistrationGroupModal extends Component<EventParticipantsRegistrationGroupModalProps, EventParticipantsRegistrationGroupModalState>{

    newGroupTemplate:Group = {
        groupName: "",
        maxGroupSize: 99,
        participantsCount: 0,
        id: 0,
        groupNumber: 0,
        organizationNumber: "",
        shootingProgramDefinitionId: 0
    }

    constructor(props: EventParticipantsRegistrationGroupModalProps) {
        super(props);
        this.state = {
            dropdownText: localizedTexts.labelExistingGroup!,
            name: "",
            number: 0,
            selectedGroup: undefined
        }
    }

    saveHandler = () => {
        var group = this.state.selectedGroup;
        if (!group){
            group = this.newGroupTemplate;
            group.groupName = this.state.name;
            group.groupNumber = this.state.number;
        }

        this.props.saveHandler(group, this.props.shootingProgramDefinition);

        this.setState({
            name: "",
            number: 0,
            selectedGroup: undefined
        });
    }

    removeHandler = () => {
        this.props.saveHandler(undefined, this.props.shootingProgramDefinition);
        this.setState({
            name: "",
            number: 0,
            selectedGroup: undefined
        });
    }

    render(){
        if (!this.props.ready) {
            return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1} aria-labelledby="groupRegistration" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className={"spinner-border"} role={"status"}/>
                    </div>
                </div>
            </div>
        }

        if (!this.props.organizationSelected){
            return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1} aria-labelledby="groupRegistration" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <h3>{localizedTexts.textSelectOrganizationFirst}</h3>
                    </div>
                </div>
            </div>
        }

        return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1}
                    aria-labelledby="groupRegistration" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="newEventModalLabel">{localizedTexts.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h2>{localizedTexts.labelExistingGroup}</h2>
                                </div>
                            </div>
                            <div className={"row"}>
                                <WinFireDropDown
                                    buttonText={this.state.dropdownText}
                                    elements={this.props.groups.filter(g => g.maxGroupSize! > g.participantsCount!).map(g => {
                                        return {
                                            text: g.groupName ?? "",
                                            key: `${g.id}`,
                                            sourceObject: g,
                                            selectFunction: (group: Group) => {
                                                this.setState({name: group.groupName!, number: group.groupNumber!, selectedGroup: group})
                                            }
                                        }
                                    })}
                                    elementFilter={(a: string, b: Group) => {
                                        return true;
                                    }}/>
                            </div>


                            <div className={"row"}>
                                <div className={"col"}>
                                    <h2>{localizedTexts.labelNewGroup}</h2>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md6"}>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="name"
                                               placeholder={localizedTexts.labelName}
                                               value={this.state.name}
                                               readOnly={this.state.selectedGroup != undefined}
                                               disabled={this.state.selectedGroup != undefined}
                                               onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                   this.setState({name: evt.currentTarget.value})
                                               }}/>
                                        <label htmlFor="name">{localizedTexts.labelName}</label>
                                    </div>
                                </div>
                                <div className={"col-md6"}>
                                    <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="name"
                                               placeholder={localizedTexts.labelNumber}
                                               value={this.state.number}
                                               readOnly={this.state.selectedGroup != undefined}
                                               disabled={this.state.selectedGroup != undefined}
                                               onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                                   this.setState({number: evt.currentTarget.valueAsNumber})
                                               }}/>
                                        <label htmlFor="name">{localizedTexts.labelNumber}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                onClick={this.saveHandler}>{localizedTexts.labelSave}
                        </button>
                        <button type="button" className="btn btn-secondary"
                                data-bs-dismiss="modal" onClick={this.removeHandler}>{localizedTexts.labelRemove}</button>
                    </div>
                </div>
            </div>
        </div>
    }

}

interface EventParticipantsRegistrationGroupModalState {
    dropdownText: string;
    name: string;
    number: number;
    selectedGroup: Group | undefined;
}

interface EventParticipantsRegistrationGroupModalProps{
    modalId: string;
    saveHandler: Function;
    groups: Group[];
    ready: boolean;
    organizationSelected: boolean;
    shootingProgramDefinition: ShootingProgramDefinition | undefined;
}