import React, {Component} from "react";
import CoreNavigation from "./CoreNavigation";
import {ShootingRange, TargetState} from "./models/Models";

import localizedTexts from "./texts/RangeAdministration.Text";
import {useParams} from "react-router";
import ApiClientFactory from "./models/ApiClientFactory";

function RangeSettingsWrapper() {
    let {rangeId} = useParams();
    let {distanceId} = useParams();
    return <RangeSettings rangeId={rangeId} distanceId={parseInt(distanceId!)}/>
}


export class RangeSettings extends Component<RangeSettingsStateProps, RangeSettingsState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: RangeSettingsStateProps) {
        super(props);
        this.state = {
            shootingRange: undefined,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            let range = await this.apiClient.api.coreShootingRangesDetail(`${this.props.rangeId}`);
            if (range.ok) {
                this.setState({shootingRange: range.data, isLoading: false});
            }
        } catch (e: any) {

        }
    }

    enableDemoMode = async (rangeId : string, distanceId : number)=>     {
        try {
            var result = await this.apiClient.api.corePublicRangeDataEnableDemoModeCreate(rangeId ?? "", distanceId, []);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on sending EnableDemoMode");
        }
    }

    disableDemoMode = async (rangeId : string, distanceId : number)=>     {
        try {
            var result = await this.apiClient.api.corePublicRangeDataDisableDemoModeCreate(rangeId ?? "", distanceId, []);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on sending DisableDemoMode");
        }
    }

    private isManualRange = (): boolean =>
    {
        var currentDiscipline = this.state.shootingRange?.shootingRangeDisciplines?.find(discipline => discipline.distanceId == this.props.distanceId);
        if (currentDiscipline?.shootingRangeTypeId === 1 || currentDiscipline?.shootingRangeTypeId === 2)
        {
            return true;
        }
        return false;
    }

    private isLonRange = (): boolean =>
    {
        var currentDiscipline = this.state.shootingRange?.shootingRangeDisciplines?.find(discipline => discipline.distanceId == this.props.distanceId);
        if (currentDiscipline?.shootingRangeTypeId === 6 || currentDiscipline?.shootingRangeTypeId === 7
            || currentDiscipline?.shootingRangeTypeId === 9 ||  currentDiscipline?.shootingRangeTypeId === 10
            || currentDiscipline?.shootingRangeTypeId === 13)
        {
            return true;
        }
        return false;
    }

    render()      {
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

    return <div className={"container-fluid"}>
        <h1>{localizedTexts.settings} {this.state.shootingRange?.name} {this.props.distanceId === 1 ? "300m" : this.props.distanceId === 2 ? "50m" : this.props.distanceId === 3 ? "25m" : "10m"}</h1>

        <p hidden={!this.isManualRange()}>{localizedTexts.nosettingsmanuelmode}</p>

        <div className={"card mt-3"} hidden={!this.isLonRange()}>
            <div className={"card-body"}>
                <h5 className={"card-text"}> Demo Mode </h5>
                <button className={"btn btn-primary"}
                        onClick={() => {
                            this.enableDemoMode(this.props.rangeId ?? "", this.props.distanceId)
                        }}
                        hidden={!this.isLonRange()}>Enable Demo Mode
                </button>
                &nbsp;
                <button className={"btn btn-primary"}
                        onClick={() => {
                            this.disableDemoMode(this.props.rangeId ?? "", this.props.distanceId)
                        }}
                        hidden={!this.isLonRange()}>Disable Demo Mode
                </button>
            </div>
        </div>

        <div className={"card mt-3"} hidden={!this.isLonRange()}>
            <div className={"card-body"}>
                <p className={"card-text"}> {localizedTexts.eventSettingsDescription} </p>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Schützennummer
                        notwendig</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Schützennummer
                        repetieren</label>
                </div>
                <button className={"btn btn-primary mt-3"}
                        onClick={() => {
                            this.disableDemoMode(this.props.rangeId ?? "", this.props.distanceId)
                        }}
                        hidden={!this.isLonRange()}>{localizedTexts.save}
                </button>
            </div>
        </div>
    </div>

    }
}

interface RangeSettingsState {
    isLoading: boolean;
    shootingRange: ShootingRange | undefined
}

interface RangeSettingsStateProps {
    rangeId: string | undefined
    distanceId: number
}

export default RangeSettingsWrapper;