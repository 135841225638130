import {useParams} from "react-router";
import React, {Component} from "react";
import localizedTexts from "./texts/EventRoundsOverview.texts";
import {Group, Participant, Round, ShootingProgramDefinition} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import {NavLink} from "react-router-dom";

import 'bootstrap/js/dist/modal'
import EventRoundsOverviewRoundModal from "./EventRoundsOverviewRoundModal";

function EventRoundOverviewWrapper(){
    let {eventId} = useParams();
    return <EventRoundsOverview eventId={eventId} />
}

export class EventRoundsOverview extends Component<EventParticipantsProps, EventRoundsState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventParticipantsProps) {
        super(props);

        this.state = {
            rounds: [],
            selectedRound: undefined,
            isLoading: true,
            roundsReady: false,
            setFile: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            var roundsResult = await this.apiClient.api.eventsRoundsDetail(`${this.props.eventId}`);
            if (roundsResult.ok) {
                this.setState({rounds: roundsResult.data, isLoading: false, roundsReady: true});
            }
        } catch (e: any) {
            alert("Failed to load rounds. Is the Connector online?");
            this.setState({isLoading: false})
        }
    }

    prepareRoundModal = async (round: Round) => {
        try {
            this.setState ({
                    selectedRound : round,
                    roundsReady : true
                }
            );
        } catch (e: any) {

        }
    }

    saveRoundModal = (round: Round|undefined) => {

    }

    private async removeRound() {

    }

    private async startRoundOnRange(round: Round) {
        if (window.confirm(localizedTexts.testStartRoundConfirmation!.replace("{nr}", `${round.roundNumber}`))) {
            var removeResult = await this.apiClient.api.eventsRoundsStartCreate(round.roundNumber!, `${this.props.eventId}` );
            if (removeResult.ok) {
                this.loadData();
            } else {
                alert("Fehlgeschlagen...");
            }
        }
    }

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            this.setState({setFile : e.target.files[0]});
        }
    };

    private async uploadCsvFile()
    {

        let file = this.state.setFile;
        if (file != null) {
            var formData = new FormData();
            formData.append('file', file);
            var resp = await this.apiClient.api.eventsRoundsCreate(`${this.props.eventId}`, { file });
            if (resp.ok) {
                alert("File uploaded successfully");
            }
        }
        else
        {
            console.log("No file selected")
        }
    }

    render(){
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

        return <div>
            <h1><span className={"bi-person-lines-fill"}></span> {localizedTexts.title}</h1>


                    <div className={"card"}>
                        <div className={"card-body"}>
                            <h5 className={"card-title"}>
                                <span className={"bi-text-wrap"}></span> Ablösungen
                            </h5>

                            <table
                                className={"table table-striped table-responsive"}
                                style={{maxWidth: "600px"}}>
                                <thead>
                                <tr>
                                    <th>Nr</th>
                                    <th>Zeit</th>
                                    <th>Teilnehmerzahl</th>
                                    <th>Aktionen</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.rounds.map((r) => {

                                        return <tr>
                                            <th style={{textAlign: "right", width: "45px"}}>{r.roundNumber}</th>
                                            <td>{r.startTime} - {r.endTime}</td>
                                            <td>{r.roundAssignments?.length}</td>
                                            <td>
                                                <button className={"btn btn-primary"}
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={"localizedTexts.labelToRegistration"}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#roundEdit"
                                                        onClick={() => {
                                                            this.prepareRoundModal(r)
                                                        }}>

                                                    <span className={"bi-pencil-square"}> </span></button>
                                                &nbsp;
                                                &nbsp;
                                                <button className={r.hasBeenStarted === false ? 'btn btn-success' : 'btn btn-light'}
                                                        onClick={() => this.startRoundOnRange(r)}><span
                                                    className={"bi-play-fill"}> </span></button>
                                                &nbsp;
                                                &nbsp;
                                                <button className={'btn btn-danger'}
                                                        onClick={() => this.removeRound()}><span
                                                    className={"bi-trash3-fill"}> </span></button>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>

                            <a className={"btn btn-primary"} target={"_blank"}
                               href={`${process.env.REACT_APP_API_LOCATION}/api/events/${this.props.eventId ?? ""}/Scorelists/scorelist/`}> {localizedTexts.labelNewRound}</a>&nbsp;
                            <br/>
                            <div style={{paddingTop: "15px", }}>
                            <input type="file" id="fileInput" onChange={this.handleFileChange}/>

                            <button className={"btn btn-primary"}
                               onClick={() => this.uploadCsvFile()}> {localizedTexts.labelImport}</button>&nbsp;
                            </div>
                        </div>
                    </div>


            <EventRoundsOverviewRoundModal
                modalId={"roundEdit"}
                saveHandler={this.saveRoundModal}
                rounds={this.state.rounds}
                ready={this.state.roundsReady}
                selectedRound={this.state.selectedRound}
                eventId={this.props.eventId}
            />

        </div>
    }
}

interface EventRoundsState {
    isLoading: boolean;
    rounds: Round[];
    selectedRound: Round | undefined;
    roundsReady: boolean;
    setFile : File | null;
}

interface EventParticipantsProps {
    eventId: string | undefined;
}

export default EventRoundOverviewWrapper;