/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActivateShootingRangeCmd {
  shootingRange?: ShootingRange;
  sendParticipants?: boolean;
}

export interface AgeCategory {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  /** @format int32 */
  ageFrom?: number;
  /** @format int32 */
  ageTo?: number;
}

export interface CoreShootingRange {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  shootingRangeDisciplines?: CoreShootingRangeDistance[] | null;
}

export interface CoreShootingRangeDistance {
  /** @format int32 */
  disciplineId?: number;
  /** @format int32 */
  laneCount?: number;
  /** @format int32 */
  shootingRangeTypeId?: number;
  /** @format int32 */
  startLaneNumber?: number;
  /** @format int32 */
  endLaneNumber?: number;
}

export interface CreateEvent {
  name?: string | null;
  shortName?: string | null;
  /** @format int32 */
  eventType?: number;
  eventOrganizer?: string | null;
  eventLocation?: string | null;
}

export interface Discipline {
  /** @format int32 */
  id?: number;
  name?: string | null;
  distance?: Distance;
  fields?: Field[] | null;
  shootingProgramDefinitions?: ShootingProgramDefinition[] | null;
}

export interface Distance {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface Event {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  eventTyp?: string | null;
}

export interface EventInfo {
  name?: string | null;
  shortName?: string | null;
  eventLocation?: string | null;
  eventOrganizer?: string | null;
  /** @format int32 */
  participantCount?: number;
}

export interface EventRegistratioInformation {
  disciplines?: Discipline[] | null;
}

export interface EventStatistics {
  eventName?: string | null;
  eventYear?: string | null;
  participantCount?: string | null;
  organizationsCount?: string | null;
  exerciseCount?: string | null;
  singleAwardReceivedCount?: string | null;
  tripleAwardReceivedCount?: string | null;
  quintupleAwardReceivedCount?: string | null;
  singleAwardCardReceivedCount?: string | null;
  tripleAwardCardReceivedCount?: string | null;
  quintupleAwardCardReceivedCount?: string | null;
  shootingProgramStatistics?: ShootingProgramStatistics[] | null;
}

export interface EventType {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
}

export interface Exercise {
  /** @format int32 */
  id?: number;
  /** @format uuid */
  exerciseResultId?: string | null;
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format double */
  result?: number | null;
  /** @format double */
  bestShotValue?: number | null;
  /** @format int32 */
  innerTenCount?: number;
  /** @format int32 */
  weaponDefinitionId?: number;
  sequences?: ExerciseSequence[] | null;
  shots?: ExerciseShot[] | null;
}

export interface ExerciseSequence {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  sequenceNumber?: number;
  /** @format double */
  sequenceResult?: number;
  isPractice?: boolean;
  /** @format date */
  date?: string;
  /** @format time */
  time?: string;
  /** @format int32 */
  shootingProgramSequenceDefinitionId?: number;
}

export interface ExerciseSettlement {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format double */
  result?: number;
  /** @format double */
  bestShotValue?: number;
  /** @format int32 */
  weaponDefinitionId?: number;
  /** @format double */
  payoutAmount?: number | null;
  awardCardGranted?: boolean;
  awardCardReceived?: boolean;
  payoutAmountReceived?: boolean;
  awardGranted?: boolean;
  awardReceived?: boolean;
  /** @format double */
  naturalPayoutAmount?: number | null;
  naturalPayoutAmountReceived?: boolean;
}

export interface ExerciseShot {
  /** @format int32 */
  shotNumber?: number;
  /** @format double */
  value?: number;
  /** @format date */
  date?: string;
  /** @format time */
  time?: string;
  isDemo?: boolean;
  isInnerTen?: boolean;
  isIllegalShot?: boolean;
  isPractice?: boolean;
  /** @format double */
  x?: number | null;
  /** @format double */
  y?: number | null;
}

export interface Field {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  weapons?: Weapon[] | null;
}

export interface Group {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  groupNumber?: number;
  groupName?: string | null;
  organizationNumber?: string | null;
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format int32 */
  participantsCount?: number;
  /** @format int32 */
  maxGroupSize?: number;
  /** @format int32 */
  groupChefId?: number | null;
}

export interface GroupChef {
  /** @format int32 */
  id?: number;
  lastName?: string | null;
  firstName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  phoneNumber?: string | null;
  streetNumber?: string | null;
  emailAddress?: string | null;
  iban?: string | null;
  bankName?: string | null;
}

export interface GroupParticipant {
  /** @format int32 */
  participantId?: number;
  participantNumber?: string | null;
  person?: Person;
  organization?: Organization;
  /** @format int32 */
  weaponId?: number | null;
}

export interface GroupWithParticipants {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  groupNumber?: number;
  groupName?: string | null;
  organizationNumber?: string | null;
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format int32 */
  participantsCount?: number;
  /** @format int32 */
  maxGroupSize?: number;
  /** @format int32 */
  groupChefId?: number | null;
  organizationName?: string | null;
  shootingProgramDefinitionName?: string | null;
  participants?: GroupParticipant[] | null;
}

export interface GroupWithParticipantsCount {
  /** @format int32 */
  groupNumber?: number;
  /** @format int32 */
  participantCount?: number;
}

export interface NewEvent {
  name?: string | null;
  shortName?: string | null;
  /** @format int32 */
  eventType?: number;
  eventOrganizer?: string | null;
  eventLocation?: string | null;
  disciplines?: NewEventDiscipline[] | null;
}

export interface NewEventAmmunition {
  name?: NewEventTranslationText;
  /** @format double */
  caliber?: number;
}

export interface NewEventDiscipline {
  distance?: string | null;
  name?: NewEventTranslationText;
  isEnabled?: boolean;
  weapons?: NewEventWeapon[] | null;
  fields?: NewEventField[] | null;
  fieldToWeaponMappings?: NewEventFieldWeaponMapping[] | null;
}

export interface NewEventField {
  name?: NewEventTranslationText;
  shortName?: NewEventTranslationText;
  /** @format int32 */
  order?: number;
  isEnabled?: boolean;
}

export interface NewEventFieldWeaponMapping {
  fieldShortName?: string | null;
  weaponShortNames?: string[] | null;
}

export interface NewEventTranslation {
  language?: string | null;
  translation?: string | null;
}

export interface NewEventTranslationText {
  defaultText?: string | null;
  translations?: NewEventTranslation[] | null;
}

export interface NewEventWeapon {
  name?: NewEventTranslationText;
  shortName?: NewEventTranslationText;
  includingAmmunition?: boolean;
  ammunition?: NewEventAmmunition;
  isEnabled?: boolean;
}

export interface Organization {
  number?: string | null;
  name?: string | null;
  shortName?: string | null;
  zipCode?: string | null;
  city?: string | null;
}

export interface Participant {
  /** @format int32 */
  participantId?: number;
  participantNumber?: string | null;
  person?: Person;
  organization?: Organization;
  startNumber?: string | null;
  shootingProgramDefinitionRegistrations?: ShootingProgramDefinitionRegistration[] | null;
}

export interface ParticipantSearch {
  participantNumber?: string | null;
  personSearch?: PersonSearch;
}

export interface ParticipantSettlement {
  /** @format int32 */
  participantId?: number;
  ageCategory?: string | null;
  participantNumber?: string | null;
  person?: RegisterPerson;
  organization?: Organization;
  exerciseSettlements?: ExerciseSettlement[] | null;
}

export interface ParticipantWithResults {
  /** @format int32 */
  id?: number;
  participantNumber?: string | null;
  person?: Person;
  ageCategory?: AgeCategory;
  organization?: Organization;
  exercises?: Exercise[] | null;
}

export interface Person {
  /** @format int32 */
  id?: number;
  licenseNumber?: string | null;
  personNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  postBox?: string | null;
  /** @format date */
  birthday?: string;
  isWoman?: boolean;
  organizations?: PersonOrganization[] | null;
}

export interface PersonMatchInOrganization {
  licenseNumber?: string | null;
  organizationNumber?: string | null;
}

export interface PersonOrganization {
  number?: string | null;
  name?: string | null;
  shortName?: string | null;
  zipCode?: string | null;
  city?: string | null;
  isAMember?: boolean;
  discipline?: Discipline;
}

export interface PersonSearch {
  personNumber?: string | null;
  licenseNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  postBox?: string | null;
  /** @format date */
  birthday?: string | null;
  organization?: Organization;
}

export interface ReShotRequestBody {
  /** @format int32 */
  programNr?: number;
  participantNr?: string | null;
}

export interface RegisterParticipant {
  participantNumber?: string | null;
  startNumber?: string | null;
  person?: RegisterPerson;
  organization?: Organization;
  shootingProgramDefinitionRegistrations?: ShootingProgramDefinitionRegistration[] | null;
}

export interface RegisterPerson {
  /** @format int32 */
  id?: number;
  licenseNumber?: string | null;
  personNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  postBox?: string | null;
  /** @format date */
  birthday?: string;
  isWoman?: boolean;
}

export interface ResultSheetDefinition {
  /** @format int32 */
  id?: number;
  name?: string | null;
  isBarcodeSheet?: boolean;
}

export interface Round {
  /** @format int32 */
  roundId?: number;
  /** @format int32 */
  roundNumber?: number;
  /** @format int32 */
  distanceId?: number;
  /** @format time */
  startTime?: string;
  /** @format time */
  endTime?: string;
  hasBeenStarted?: boolean;
  roundAssignments?: RoundAssignment[] | null;
}

export interface RoundAssignment {
  /** @format int32 */
  laneNr?: number;
  /** @format int32 */
  programNr?: number;
  participant?: Participant;
  isRegisteredOnConnector?: boolean;
}

export interface ScoreListField {
  /** @format int32 */
  id?: number;
  shortName?: string | null;
}

export interface ScorelistDefinition {
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  name?: string | null;
  isGroupExercise?: boolean;
  isSection?: boolean;
  hasExcelTemplate?: boolean;
  hasGroupExcelTemplate?: boolean;
  fields?: ScoreListField[] | null;
}

export interface ShootingProgramDefinition {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  isGroupExercise?: boolean;
  isMandatory?: boolean;
  /** @format int32 */
  maxPassen?: number;
  /** @format int32 */
  visualOrder?: number;
  isBestShotRequired?: boolean;
  shotsRequired?: boolean;
  sequencesRequired?: boolean;
  innerTenCountRequired?: boolean;
  /** @format int32 */
  shotCount?: number;
  shootingProgramDefinitionPrices?: ShootingProgramDefinitionPrice[] | null;
  shootingProgramSequences?: ShootingProgramSequence[] | null;
  hasAward?: boolean;
  hasAwardCard?: boolean;
  hasAwardNatural?: boolean;
  awardNaturalName?: string | null;
  hasPayout?: boolean;
  hasNaturalPayout?: boolean;
  isCalculated?: boolean;
  isOrderable?: boolean;
  /** @format int32 */
  groupSize?: number;
  resultSheetDefinition?: ResultSheetDefinition;
  /** @format int32 */
  singleScoreListDefinitionId?: number | null;
  hasAwardCardTemplateFile?: boolean;
}

export interface ShootingProgramDefinitionPrice {
  ageCategory?: AgeCategory;
  /** @format double */
  price?: number;
  isDefault?: boolean;
  /** @format double */
  groupPriceAdd?: number;
  /** @format double */
  priceWithoutAmmo?: number;
  /** @format double */
  nachdoppelPriceSub?: number;
}

export interface ShootingProgramDefinitionRegistration {
  /** @format int32 */
  shootingProgramDefinitionId?: number;
  /** @format int32 */
  weaponId?: number;
  /** @format int32 */
  passenCount?: number;
  isOrdered?: boolean;
  group?: Group;
  /** @format double */
  price?: number;
}

export interface ShootingProgramDefinitionStatisticsWeapon {
  ageCategory?: string | null;
  freiGwCount?: string | null;
  karCount?: string | null;
  stdGwCount?: string | null;
  stgw5702Count?: string | null;
  stgw5703Count?: string | null;
  stgw90Count?: string | null;
  stgw90RingCount?: string | null;
}

export interface ShootingProgramSequence {
  /** @format int32 */
  order?: number;
  /** @format int32 */
  shotCount?: number;
  firekind?: string | null;
  /** @format int32 */
  firekindId?: number;
  /** @format int32 */
  maxShotvalue?: number;
}

export interface ShootingProgramStatistics {
  shootingProgramName?: string | null;
  /** @format int32 */
  shootingProgramId?: number;
  exerciseCount?: string | null;
  orderStatistics?: ShootingProgramStatisticsOrderCounts[] | null;
  munitionStatistics?: ShootingProgramStatisticsMunition[] | null;
  awardStatistics?: ShootingProgramStatisticsAwards[] | null;
  payoutStatictics?: ShootingProgramStatisticsPayout[] | null;
  weaponStatictics?: ShootingProgramDefinitionStatisticsWeapon[] | null;
}

export interface ShootingProgramStatisticsAwards {
  weaponName?: string | null;
  orderedCount?: string | null;
  awardReceivedCount?: string | null;
  awardCardReceivedCount?: string | null;
  quota?: string | null;
}

export interface ShootingProgramStatisticsMunition {
  name?: string | null;
  count?: string | null;
}

export interface ShootingProgramStatisticsOrderCounts {
  ageCategory?: string | null;
  orderCount?: string | null;
  income?: string | null;
  programTotalCharges?: string | null;
  programCharges?: string | null;
}

export interface ShootingProgramStatisticsPayout {
  weaponName?: string | null;
  orderedCount?: string | null;
  payoutReceivedCount?: string | null;
  payoutAmount?: string | null;
  quota?: string | null;
}

export interface ShootingRange {
  number?: string | null;
  name?: string | null;
  description?: string | null;
  city?: string | null;
  /** @format int32 */
  disciplineId?: number;
  disciplineName?: string | null;
  shootingRangeType?: string | null;
  /** @format int32 */
  laneFrom?: number;
  /** @format int32 */
  laneTo?: number;
  isActive?: boolean;
}

export interface TmpCapacity {
  /** @format int32 */
  shootingProgrammDefinitionId?: number;
  /** @format int32 */
  dayId?: number;
}

export interface TmpCapacityInformation {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  eventId?: number;
  /** @format int32 */
  shootingProgrammDefinitionId?: number;
  /** @format int32 */
  dayId?: number;
  /** @format int32 */
  targetCount?: number;
  /** @format int32 */
  timeContigent?: number;
  /** @format int32 */
  timeSlot?: number;
}

export interface Weapon {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  priceIncludingAmmo?: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title WinFire.Event
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsEventInformationDetail
     * @request GET:/api/events/{winFireEventId}/EventInformation
     */
    eventsEventInformationDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<EventInfo, any>({
        path: `/api/events/${winFireEventId}/EventInformation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsEventInformationRegistrationinformationDetail
     * @request GET:/api/events/{winFireEventId}/EventInformation/registrationinformation
     */
    eventsEventInformationRegistrationinformationDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<EventRegistratioInformation, any>({
        path: `/api/events/${winFireEventId}/EventInformation/registrationinformation`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsEventInformationStatisticsDetail
     * @request GET:/api/events/{winFireEventId}/EventInformation/statistics
     */
    eventsEventInformationStatisticsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<EventStatistics, any>({
        path: `/api/events/${winFireEventId}/EventInformation/statistics`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsEventInformationStatisticsdocumentDetail
     * @request GET:/api/events/{winFireEventId}/EventInformation/statisticsdocument
     */
    eventsEventInformationStatisticsdocumentDetail: (
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/EventInformation/statisticsdocument`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsEventInformationImportssvdataCreate
     * @request POST:/api/events/{winFireEventId}/EventInformation/importssvdata
     */
    eventsEventInformationImportssvdataCreate: (
      winFireEventId: string,
      data: {
        /** @format binary */
        communitiesfile?: File;
        /** @format binary */
        shootersfile?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/EventInformation/importssvdata`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventInformation
     * @name EventsEventInformationSeedSampleDataCreate
     * @request POST:/api/events/{winFireEventId}/EventInformation/seedSampleData/{eventName}
     */
    eventsEventInformationSeedSampleDataCreate: (
      eventName: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/EventInformation/seedSampleData/${eventName}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventManagement
     * @name EventsEventManagementShootingrangesDetail
     * @request GET:/api/events/{winFireEventId}/EventManagement/shootingranges
     */
    eventsEventManagementShootingrangesDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<ShootingRange[], any>({
        path: `/api/events/${winFireEventId}/EventManagement/shootingranges`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventManagement
     * @name EventsEventManagementShootingrangesAddCreate
     * @request POST:/api/events/{winFireEventId}/EventManagement/shootingranges/add/{distanceId}
     */
    eventsEventManagementShootingrangesAddCreate: (
      distanceId: number,
      winFireEventId: string,
      data: CoreShootingRange,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/EventManagement/shootingranges/add/${distanceId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventManagement
     * @name EventsEventManagementShootingrangesRemoveDelete
     * @request DELETE:/api/events/{winFireEventId}/EventManagement/shootingranges/remove/{distanceId}
     */
    eventsEventManagementShootingrangesRemoveDelete: (
      distanceId: number,
      winFireEventId: string,
      data: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/EventManagement/shootingranges/remove/${distanceId}`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventManagement
     * @name EventsEventManagementShootingrangesActivateCreate
     * @request POST:/api/events/{winFireEventId}/EventManagement/shootingranges/activate
     */
    eventsEventManagementShootingrangesActivateCreate: (
      winFireEventId: string,
      data: ActivateShootingRangeCmd,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/EventManagement/shootingranges/activate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventManagement
     * @name EventsEventManagementShootingrangesDeactivateCreate
     * @request POST:/api/events/{winFireEventId}/EventManagement/shootingranges/deactivate
     */
    eventsEventManagementShootingrangesDeactivateCreate: (
      winFireEventId: string,
      data: ShootingRange,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/EventManagement/shootingranges/deactivate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsEventsInitCreate
     * @request POST:/api/events/Events/init
     */
    eventsEventsInitCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/Events/init`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsEventsEventinfoDetail
     * @request GET:/api/events/Events/eventinfo/{eventInfoType}
     */
    eventsEventsEventinfoDetail: (eventInfoType: number, params: RequestParams = {}) =>
      this.request<NewEvent, any>({
        path: `/api/events/Events/eventinfo/${eventInfoType}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsEventsList
     * @request GET:/api/events/Events
     */
    eventsEventsList: (params: RequestParams = {}) =>
      this.request<Event[], any>({
        path: `/api/events/Events`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsEventsCreate
     * @request POST:/api/events/Events
     */
    eventsEventsCreate: (data: CreateEvent, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/Events`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsEventsRegistrationEventsList
     * @request GET:/api/events/Events/registration/events
     */
    eventsEventsRegistrationEventsList: (params: RequestParams = {}) =>
      this.request<Event[], any>({
        path: `/api/events/Events/registration/events`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsEventsEventtypesList
     * @request GET:/api/events/Events/eventtypes
     */
    eventsEventsEventtypesList: (params: RequestParams = {}) =>
      this.request<EventType[], any>({
        path: `/api/events/Events/eventtypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupChef
     * @name EventsGroupChefCreate
     * @request POST:/api/events/{winFireEventId}/GroupChef
     */
    eventsGroupChefCreate: (winFireEventId: string, data: GroupChef, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/events/${winFireEventId}/GroupChef`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupChef
     * @name EventsGroupChefDetail
     * @request GET:/api/events/{winFireEventId}/GroupChef/{groupChefId}
     */
    eventsGroupChefDetail: (groupChefId: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<GroupChef, any>({
        path: `/api/events/${winFireEventId}/GroupChef/${groupChefId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags GroupChef
     * @name EventsGroupChefUpdate
     * @request PUT:/api/events/{winFireEventId}/GroupChef/{groupChefId}
     */
    eventsGroupChefUpdate: (groupChefId: number, winFireEventId: string, data: GroupChef, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/GroupChef/${groupChefId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsDetail
     * @request GET:/api/events/{winFireEventId}/Groups
     */
    eventsGroupsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<GroupWithParticipants[], any>({
        path: `/api/events/${winFireEventId}/Groups`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsCreate
     * @request POST:/api/events/{winFireEventId}/Groups
     */
    eventsGroupsCreate: (winFireEventId: string, data: Group, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/events/${winFireEventId}/Groups`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsOrganisationDetail
     * @request GET:/api/events/{winFireEventId}/Groups/organisation/{organizationNumber}
     */
    eventsGroupsOrganisationDetail: (organizationNumber: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<GroupWithParticipants[], any>({
        path: `/api/events/${winFireEventId}/Groups/organisation/${organizationNumber}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsUpdate
     * @request PUT:/api/events/{winFireEventId}/Groups/{groupId}
     */
    eventsGroupsUpdate: (groupId: number, winFireEventId: string, data: Group, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Groups/${groupId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsDelete
     * @request DELETE:/api/events/{winFireEventId}/Groups/{groupId}
     */
    eventsGroupsDelete: (groupId: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Groups/${groupId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsGroupNrUpdate
     * @request PUT:/api/events/{winFireEventId}/Groups/{groupId}/groupNr
     */
    eventsGroupsGroupNrUpdate: (groupId: number, winFireEventId: string, data: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Groups/${groupId}/groupNr`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsGroupNameUpdate
     * @request PUT:/api/events/{winFireEventId}/Groups/{groupId}/groupName
     */
    eventsGroupsGroupNameUpdate: (groupId: number, winFireEventId: string, data: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Groups/${groupId}/groupName`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsDelete2
     * @request DELETE:/api/events/{winFireEventId}/Groups/{groupId}/{participantId}
     * @originalName eventsGroupsDelete
     * @duplicate
     */
    eventsGroupsDelete2: (groupId: number, participantId: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Groups/${groupId}/${participantId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsUpdate2
     * @request PUT:/api/events/{winFireEventId}/Groups/{groupId}/{participantId}
     * @originalName eventsGroupsUpdate
     * @duplicate
     */
    eventsGroupsUpdate2: (groupId: number, participantId: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Groups/${groupId}/${participantId}`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsParticipantsUpdate
     * @request PUT:/api/events/{winFireEventId}/Groups/{groupId}/Participants
     */
    eventsGroupsParticipantsUpdate: (
      groupId: number,
      winFireEventId: string,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Groups/${groupId}/Participants`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsAvailableParticipantsDetail
     * @request GET:/api/events/{winFireEventId}/Groups/availableParticipants/{organizationNumber}/{shootingProgramDefinitionId}
     */
    eventsGroupsAvailableParticipantsDetail: (
      shootingProgramDefinitionId: number,
      organizationNumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<GroupParticipant[], any>({
        path: `/api/events/${winFireEventId}/Groups/availableParticipants/${organizationNumber}/${shootingProgramDefinitionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name EventsGroupsShootingProgramGroupsWithParticipantsCountDetail
     * @request GET:/api/events/{winFireEventId}/Groups/shootingProgram/{shootingProgramDefinitionId}/groupsWithParticipantsCount
     */
    eventsGroupsShootingProgramGroupsWithParticipantsCountDetail: (
      shootingProgramDefinitionId: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<GroupWithParticipantsCount[], any>({
        path: `/api/events/${winFireEventId}/Groups/shootingProgram/${shootingProgramDefinitionId}/groupsWithParticipantsCount`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsOrganizationsDetail
     * @request GET:/api/events/{winFireEventId}/Organizations
     */
    eventsOrganizationsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<Organization[], any>({
        path: `/api/events/${winFireEventId}/Organizations`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsOrganizationsGroupsDetail
     * @request GET:/api/events/{winFireEventId}/Organizations/{organizationnumber}/groups/{shootingProgramDefinitionId}
     */
    eventsOrganizationsGroupsDetail: (
      organizationnumber: string,
      shootingProgramDefinitionId: number,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<Group[], any>({
        path: `/api/events/${winFireEventId}/Organizations/${organizationnumber}/groups/${shootingProgramDefinitionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name EventsOrganizationsOrgChefUpdate
     * @request PUT:/api/events/{winFireEventId}/Organizations/{organizationNumber}/orgChef
     */
    eventsOrganizationsOrgChefUpdate: (
      organizationNumber: string,
      winFireEventId: string,
      data: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Organizations/${organizationNumber}/orgChef`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsDetail
     * @request GET:/api/events/{winFireEventId}/Participants
     */
    eventsParticipantsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<Participant[], any>({
        path: `/api/events/${winFireEventId}/Participants`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsCreate
     * @request POST:/api/events/{winFireEventId}/Participants
     */
    eventsParticipantsCreate: (winFireEventId: string, data: RegisterParticipant, params: RequestParams = {}) =>
      this.request<Participant, any>({
        path: `/api/events/${winFireEventId}/Participants`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsDelete
     * @request DELETE:/api/events/{winFireEventId}/Participants/{participantNumber}
     */
    eventsParticipantsDelete: (participantNumber: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Participants/${participantNumber}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsResendCreate
     * @request POST:/api/events/{winFireEventId}/Participants/{participantNumber}/resend
     */
    eventsParticipantsResendCreate: (participantNumber: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Participants/${participantNumber}/resend`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsByLicenseDetail
     * @request GET:/api/events/{winFireEventId}/Participants/byLicense/{licenseNumber}
     */
    eventsParticipantsByLicenseDetail: (licenseNumber: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<Participant, any>({
        path: `/api/events/${winFireEventId}/Participants/byLicense/${licenseNumber}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsSearchCreate
     * @request POST:/api/events/{winFireEventId}/Participants/search
     */
    eventsParticipantsSearchCreate: (winFireEventId: string, data: ParticipantSearch, params: RequestParams = {}) =>
      this.request<Participant[], any>({
        path: `/api/events/${winFireEventId}/Participants/search`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsWithResultsDetail
     * @request GET:/api/events/{winFireEventId}/Participants/{participantNumber}/withResults
     */
    eventsParticipantsWithResultsDetail: (
      participantNumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ParticipantWithResults, any>({
        path: `/api/events/${winFireEventId}/Participants/${participantNumber}/withResults`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsUpdateresultsUpdate
     * @request PUT:/api/events/{winFireEventId}/Participants/updateresults
     */
    eventsParticipantsUpdateresultsUpdate: (
      winFireEventId: string,
      data: ParticipantWithResults,
      params: RequestParams = {},
    ) =>
      this.request<ParticipantWithResults, any>({
        path: `/api/events/${winFireEventId}/Participants/updateresults`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsSettlementDetail
     * @request GET:/api/events/{winFireEventId}/Participants/{participantNumber}/settlement
     */
    eventsParticipantsSettlementDetail: (
      participantNumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<ParticipantSettlement, any>({
        path: `/api/events/${winFireEventId}/Participants/${participantNumber}/settlement`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsUpdatesettlementUpdate
     * @request PUT:/api/events/{winFireEventId}/Participants/updatesettlement
     */
    eventsParticipantsUpdatesettlementUpdate: (
      winFireEventId: string,
      data: ParticipantSettlement,
      params: RequestParams = {},
    ) =>
      this.request<ParticipantWithResults, any>({
        path: `/api/events/${winFireEventId}/Participants/updatesettlement`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsResultsheetDetail
     * @request GET:/api/events/{winFireEventId}/Participants/{participantNumber}/resultsheet/{resultsheetId}
     */
    eventsParticipantsResultsheetDetail: (
      participantNumber: string,
      resultsheetId: number,
      winFireEventId: string,
      query?: {
        /** @format int32 */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Participants/${participantNumber}/resultsheet/${resultsheetId}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsResultsheetCreate
     * @request POST:/api/events/{winFireEventId}/Participants/resultsheet/{resultsheetId}
     */
    eventsParticipantsResultsheetCreate: (
      resultsheetId: number,
      winFireEventId: string,
      data: string[],
      query?: {
        /** @format int32 */
        offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Participants/resultsheet/${resultsheetId}`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsAccountingsheetDetail
     * @request GET:/api/events/{winFireEventId}/Participants/{participantNumber}/accountingsheet
     */
    eventsParticipantsAccountingsheetDetail: (
      participantNumber: string,
      winFireEventId: string,
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Participants/${participantNumber}/accountingsheet`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Participants
     * @name EventsParticipantsAwardcardDetail
     * @request GET:/api/events/{winFireEventId}/Participants/{participantNumber}/awardcard
     */
    eventsParticipantsAwardcardDetail: (
      participantNumber: string,
      winFireEventId: string,
      query?: {
        /** @format int32 */
        shootingProgramDefinition?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Participants/${participantNumber}/awardcard`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags People
     * @name EventsPeopleDetail
     * @request GET:/api/events/{winFireEventId}/People/{id}
     */
    eventsPeopleDetail: (id: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<Person, any>({
        path: `/api/events/${winFireEventId}/People/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags People
     * @name EventsPeopleByLicenseDetail
     * @request GET:/api/events/{winFireEventId}/People/byLicense/{licenseNr}
     */
    eventsPeopleByLicenseDetail: (licenseNr: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<Person, any>({
        path: `/api/events/${winFireEventId}/People/byLicense/${licenseNr}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags People
     * @name EventsPeopleByPersonNrDetail
     * @request GET:/api/events/{winFireEventId}/People/byPersonNr/{personNr}
     */
    eventsPeopleByPersonNrDetail: (personNr: string, winFireEventId: string, params: RequestParams = {}) =>
      this.request<Person, any>({
        path: `/api/events/${winFireEventId}/People/byPersonNr/${personNr}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags People
     * @name EventsPeopleSearchCreate
     * @request POST:/api/events/{winFireEventId}/People/search
     */
    eventsPeopleSearchCreate: (winFireEventId: string, data: PersonSearch, params: RequestParams = {}) =>
      this.request<Person[], any>({
        path: `/api/events/${winFireEventId}/People/search`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags People
     * @name EventsPeopleMatchCreate
     * @request POST:/api/events/{winFireEventId}/People/match
     */
    eventsPeopleMatchCreate: (winFireEventId: string, data: PersonMatchInOrganization, params: RequestParams = {}) =>
      this.request<Person[], any>({
        path: `/api/events/${winFireEventId}/People/match`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Persons
     * @name EventsPersonsRoleeditorCreate
     * @request POST:/api/events/Persons/roleeditor
     */
    eventsPersonsRoleeditorCreate: (
      query?: {
        /** @format int32 */
        eventId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/Persons/roleeditor`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rounds
     * @name EventsRoundsDetail
     * @request GET:/api/events/{winFireEventId}/Rounds
     */
    eventsRoundsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<Round[], any>({
        path: `/api/events/${winFireEventId}/Rounds`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rounds
     * @name EventsRoundsCreate
     * @request POST:/api/events/{winFireEventId}/Rounds
     */
    eventsRoundsCreate: (
      winFireEventId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Rounds`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rounds
     * @name EventsRoundsStartCreate
     * @request POST:/api/events/{winFireEventId}/Rounds/{roundNr}/start
     */
    eventsRoundsStartCreate: (roundNr: number, winFireEventId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Rounds/${roundNr}/start`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rounds
     * @name EventsRoundsLaneReshootCreate
     * @request POST:/api/events/{winFireEventId}/Rounds/{roundNr}/lane/{laneNr}/reshoot
     */
    eventsRoundsLaneReshootCreate: (
      roundNr: number,
      laneNr: number,
      winFireEventId: string,
      data: ReShotRequestBody,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Rounds/${roundNr}/lane/${laneNr}/reshoot`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Rounds
     * @name EventsRoundsLaneAddCreate
     * @request POST:/api/events/{winFireEventId}/Rounds/{roundNr}/lane/{laneNr}/add/{participantNr}
     */
    eventsRoundsLaneAddCreate: (
      roundNr: number,
      laneNr: number,
      participantNr: number,
      winFireEventId: string,
      query?: {
        /** @format int32 */
        programNr?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${winFireEventId}/Rounds/${roundNr}/lane/${laneNr}/add/${participantNr}`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Scorelists
     * @name EventsScorelistsDetail
     * @request GET:/api/events/{winFireEventId}/Scorelists
     */
    eventsScorelistsDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<ScorelistDefinition[], any>({
        path: `/api/events/${winFireEventId}/Scorelists`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Scorelists
     * @name EventsScorelistsScorelistDetail
     * @request GET:/api/events/{winFireEventId}/Scorelists/scorelist/{shootingProgramDefinitionId}
     */
    eventsScorelistsScorelistDetail: (
      shootingProgramDefinitionId: number,
      fieldId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Scorelists/scorelist/${shootingProgramDefinitionId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Scorelists
     * @name EventsScorelistsScorelistDetail2
     * @request GET:/api/events/{winFireEventId}/Scorelists/scorelist/{shootingProgramDefinitionId}/{fieldId}
     * @originalName eventsScorelistsScorelistDetail
     * @duplicate
     */
    eventsScorelistsScorelistDetail2: (
      shootingProgramDefinitionId: number,
      fieldId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Scorelists/scorelist/${shootingProgramDefinitionId}/${fieldId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Scorelists
     * @name EventsScorelistsScorelistGroupsDetail
     * @request GET:/api/events/{winFireEventId}/Scorelists/scorelist/{shootingProgramDefinitionId}/groups
     */
    eventsScorelistsScorelistGroupsDetail: (
      shootingProgramDefinitionId: number,
      fieldId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Scorelists/scorelist/${shootingProgramDefinitionId}/groups`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Scorelists
     * @name EventsScorelistsScorelistGroupsDetail2
     * @request GET:/api/events/{winFireEventId}/Scorelists/scorelist/{shootingProgramDefinitionId}/{fieldId}/groups
     * @originalName eventsScorelistsScorelistGroupsDetail
     * @duplicate
     */
    eventsScorelistsScorelistGroupsDetail2: (
      shootingProgramDefinitionId: number,
      fieldId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Scorelists/scorelist/${shootingProgramDefinitionId}/${fieldId}/groups`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Scorelists
     * @name EventsScorelistsScorelistSectionDetail
     * @request GET:/api/events/{winFireEventId}/Scorelists/scorelist/{shootingProgramDefinitionId}/section
     */
    eventsScorelistsScorelistSectionDetail: (
      shootingProgramDefinitionId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Scorelists/scorelist/${shootingProgramDefinitionId}/section`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Scorelists
     * @name EventsScorelistsScorelistSectionwithpayoutDetail
     * @request GET:/api/events/{winFireEventId}/Scorelists/scorelist/{sectionShootingProgramDefinitionId}/sectionwithpayout/{payoutShootingProgramDefinitionId}
     */
    eventsScorelistsScorelistSectionwithpayoutDetail: (
      sectionShootingProgramDefinitionId: number,
      payoutShootingProgramDefinitionId: number,
      winFireEventId: string,
      query?: {
        /** @default "pdf" */
        fileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/api/events/${winFireEventId}/Scorelists/scorelist/${sectionShootingProgramDefinitionId}/sectionwithpayout/${payoutShootingProgramDefinitionId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TmpCapacityInformation
     * @name EventsTmpCapacityInformationCreate
     * @request POST:/api/events/{winFireEventId}/TmpCapacityInformation
     */
    eventsTmpCapacityInformationCreate: (winFireEventId: string, data: TmpCapacity, params: RequestParams = {}) =>
      this.request<TmpCapacityInformation, any>({
        path: `/api/events/${winFireEventId}/TmpCapacityInformation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TmpCapacityInformation
     * @name EventsTmpCapacityInformationDetail
     * @request GET:/api/events/{winFireEventId}/TmpCapacityInformation
     */
    eventsTmpCapacityInformationDetail: (winFireEventId: string, params: RequestParams = {}) =>
      this.request<TmpCapacityInformation[], any>({
        path: `/api/events/${winFireEventId}/TmpCapacityInformation`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
