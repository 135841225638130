import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "title" : "Startseite",
    },
    "fr": {

    },
    "it": {

    },
    "en": {

    }
});

export default localizedTexts;