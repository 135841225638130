import {HttpResponse} from "../../event/models/Models";

export default class PrintDocument{
    static async printPdfDocument(pdfResult: HttpResponse<File, any>) {
        var blob = await pdfResult.blob();
        let pdfData = blob.slice(0, blob.size, 'application/pdf');
        let blobURL = URL.createObjectURL(pdfData);

        let iframe = document.createElement('iframe');
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;

        iframe.onload = function () {
            setTimeout(function () {
                iframe.focus();
                iframe.contentWindow!.print();
            }, 1);
        };
    }
}