import {Component} from "react";
import CoreNavigation from "./CoreNavigation";

import localizedTexts from "./texts/CoreOverview.Text";

function CoreOverview() {
    return <div className={"container-fluid"}>
        <h1>{localizedTexts.title}</h1>
        <h2>{localizedTexts.welcometextheading}</h2>
        <p>{localizedTexts.welcometext}</p>
    </div>
}

export default CoreOverview;