import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Neue Schiessanlage erfassen",
        "labelName":"Name",
        "labelCity":"Ort",
        "labelDescription":"Beschreibung",
        "labelId":"ID",
        "labelStreet":"Strasse",
        "labelZipCode": "PLZ",
        "labelSave":"Speichern",
        "labelCancel":"Abbrechen",
        "labelDisciplines":"Disziplin",
        "headerDisciplineName":"Diszipline",
        "headerLaneCount":"Anzahl Scheiben",
        "headerStartLaneNumber":"Erste Scheibennummer",
        "headerEndLaneNumber":"Letzte Scheibennummber",
        "headerRangeType":"Typ",
        "labelAddDiscipline":"Weitere Disziplin Hinzufügen"
    },
    "fr": {
        "title" : "enregistrer un nouveaux stand de tir",
        "labelName":"nom",
        "labelCity":"lieu",
        "labelDescription":"description",
        "labelId":"ID",
        "labelStreet":"rue",
        "labelZipCode": "code postal",
        "labelSave":"enregistrer",
        "labelCancel":"annuler",
        "labelDisciplines":"disciplines",
        "headerDisciplineName":"discipline",
        "headerLaneCount":"nombre de cibles",
        "headerStartLaneNumber":"premier nombre de cible",
        "headerEndLaneNumber":"dernier nombre de cible",
        "headerRangeType":"type",
        "labelAddDiscipline":"ajouter une autre discipline"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;