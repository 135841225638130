import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Ablösung",
        "labelName":"Gruppenname",
        "labelNumber":"Gruppennummer",
        "labelNewGroup":"Gruppe",
        "labelParticipantsInRound":"Teilnehmer der Ablösung",
        "textSelectOrganizationFirst":"Bitte zuerst den Schützen und den Verein auswählen",
        "labelSave":"Speichern",
        "labelAddRoundAssignment":"Hinzufügen",
        "labelAddShots":"Nachschiessen",
        "labelToResult":"Zum Resultat",
        "textConfirmReShooting": "Sollen zusätzliche Schüsse gestartet werden? Scheibe:",

    },
    "fr": {
        "title" : "Ronde",
        "labelName":"Nom du groupe",
        "labelNumber":"No. du groupe",
        "labelNewGroup":"Group",
        "labelParticipantsInRound":"Participans de la ronde",
        "textSelectOrganizationFirst":"Veuillez d'abord choisir le tireur et l' association",
        "labelSave":"Enregistrer",
        "labelAddRoundAssignment":"Ajouter",
        "labelAddShots":"tir complémentaire",
        "labelToResult":"vers le resultat",
        "textConfirmReShooting": "Veuillez lancer plus des coups? cible:",
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;