import {Component} from "react";
import {NavLink} from "react-router-dom";
import localizedTexts from "../texts/EventGroupsNavigation.texts";

function EventRoundsNavigationWrapper(){

    return <EventRoundsNavigation />
}

export class EventRoundsNavigation extends Component<EventRoundsNavigationProps, EventRoundsNavigationState>{

    render(){
        return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
            <NavLink to={"overview"} className="btn btn-outline-dark">
                <span className={"bi-book"}> </span>{localizedTexts.overview}
            </NavLink>
        </div>
    }
}

interface EventRoundsNavigationState {

}

interface EventRoundsNavigationProps {

}

export default EventRoundsNavigationWrapper;