import {useParams} from "react-router";
import {EventParticipantsOverview} from "./EventParticipantsOverview";
import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/EventParticipantsSettlement.texts";
import {
    EventRegistratioInformation, ExerciseSettlement,
    ParticipantSettlement,
    ParticipantWithResults,
    ShootingProgramDefinition
} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import PrintDocument from "../common/Helpers/PrintDocument";

function EventParticipantsSettlementWrapper(){
    let {eventId, participantnumber} = useParams();
    return <EventParticipantsSettlement eventId={eventId} participantNumber={participantnumber} />
}

export class EventParticipantsSettlement extends Component<EventParticipantsSettlementProps, EventParticipantsSettlementState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventParticipantsSettlementProps) {
        super(props);

        this.state = {
            eventRegistrationInformation: undefined,
            participant: undefined,
            allWeapons: [],
            shootingProgramDefinitions: {},
            isLoading: true,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            var infos = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(this.props.eventId ?? "");
            if (infos.ok) {
                var weapons: any = {};
                var shootingProgramDefinitions: any = {};
                if (infos.data.disciplines?.length && infos.data.disciplines?.length > 0) {
                    infos.data.disciplines.forEach(d => {
                        d.shootingProgramDefinitions?.forEach(s => {
                            var shootingProgramDefinitionId = `${s.id}`;
                            shootingProgramDefinitions[shootingProgramDefinitionId] = s;
                        })
                        d.fields?.forEach(f => {
                            f.weapons?.forEach(w => {
                                var weaponId = `${w.id}`;
                                weapons[weaponId] = w;
                            })
                        })
                    });
                }

                this.setState({
                    eventRegistrationInformation: infos.data,
                    allWeapons: weapons,
                    shootingProgramDefinitions: shootingProgramDefinitions,
                    isLoading: false
                });

                if (this.props.participantNumber) {
                    this.getParticipant(this.props.participantNumber);
                }
            }
        } catch (e: any) {

        }
    }

    handleSearchFieldChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        var newValue = evt.currentTarget.value;
        if (newValue.length < 10 || !this.getParticipant(evt.currentTarget.value)){
            this.setState({participant: {
                    participantNumber: newValue
                }})
        }
    }

    handlePrintAwardCard = async  (participantNumber: string, shootingProgramDefinition: number) => {
        try {
            var result = await this.apiClient.api.eventsParticipantsAwardcardDetail(participantNumber, this.props.eventId ?? "", {shootingProgramDefinition})
            if (result.ok) {
                await PrintDocument.printPdfDocument(result);
            }
        }catch (e:any){

        }
    }

    getParticipant = async (participantNumber: string):Promise<boolean> => {

        var result = undefined;
        try {
            if (participantNumber.length == 10) {
                result = await this.apiClient.api.eventsParticipantsSettlementDetail(participantNumber, this.props.eventId ?? "");
            }
        } catch (e) {
        }
        if (result && result.ok){
            this.setState({participant: result.data});
        }else{
            return false;
        }

        return true;
    }

    formatDate(dateString?: string): string {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString(); // You can pass locale as a parameter here if needed
    }

    getShooterInfo(){
        const birthday = this.state.participant?.person?.birthday;

        return <div>
            <div className={"row"}>
                <div className="form-group col-md-6">
                    <label htmlFor="shooternumber">{localizedTexts.labelBarcode}</label>
                    <input type="text" value={this.state.participant?.participantNumber ?? ""} name="shooternumber"
                           onChange={this.handleSearchFieldChange} className="form-control"
                           id="shooternumber" placeholder="" aria-describedby="shooternumber"
                           autoFocus={true}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <h3>{localizedTexts.labelShooter}</h3>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelPersonNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.personNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLicenseNumber}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.licenseNumber}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelLastName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.lastName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelFirstName}: </label>
                </div>
                <div className={"col-md-6"}>
                    <span> {this.state.participant?.person?.firstName}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelCity}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.person?.city}</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelBirthdate}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {birthday ? this.formatDate(birthday) : "n/a"} ({this.state.participant?.ageCategory})</span>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <label>{localizedTexts.labelOrganization}: </label>
                </div>
                <div className={"col-md-8"}>
                    <span> {this.state.participant?.organization?.name} {this.state.participant?.organization?.city}</span>
                </div>
            </div>
        </div>
    }

    getShootingPrograms() {
        const exercisesHaveAwardCount = this.state.participant?.exerciseSettlements?.filter(settlement => settlement.awardGranted).length ?? 0;
        const exercisesHaveAwardCardCount = this.state.participant?.exerciseSettlements?.filter(settlement => settlement.awardCardGranted).length ?? 0;
        const exercisesHavePayoutCount = this.state.participant?.exerciseSettlements?.filter(settlement => settlement.payoutAmount).length ?? 0;

        return <div>
            <table className={"table table-striped"}>
                <tbody>
                {
                    this.state.participant?.exerciseSettlements
                        ?.sort((a, b) => this.state.shootingProgramDefinitions[`${a.shootingProgramDefinitionId}`]?.visualOrder! - this.state.shootingProgramDefinitions[`${b.shootingProgramDefinitionId}`]?.visualOrder!)
                        .filter(e => this.state.shootingProgramDefinitions[`${e.shootingProgramDefinitionId}`]?.singleScoreListDefinitionId != null )
                        .map((e) => {
                            var shootingProgramDefinition: ShootingProgramDefinition = this.state.shootingProgramDefinitions[`${e.shootingProgramDefinitionId}`];

                            return <tr key={e.id}>
                                <td>
                                    {shootingProgramDefinition.name}
                                </td>
                                <td>
                                    {localizedTexts.labelResult}:&nbsp;
                                    {
                                        e.result
                                    }
                                </td>
                                <td>
                                    {
                                        this.getAwardInfo(shootingProgramDefinition, e)
                                    }
                                    {
                                        this.getPayoutInfo(shootingProgramDefinition, e)
                                    }
                                    {
                                        this.getNaturalPayoutInfo(shootingProgramDefinition, e)
                                    }
                                </td>
                            </tr>
                        })
                }

                {
                    (exercisesHaveAwardCount > 1 || exercisesHaveAwardCardCount > 1) || (exercisesHavePayoutCount > 1) ?
                        <tr>
                            <td><strong>Alle</strong></td>
                            <td></td>
                            <td>

                                <div className={"row"}>
                                    <div className={"col-md-5"}>
                                        {
                                        (exercisesHavePayoutCount > 0) ?

                                        <button className={"btn btn-outline-primary registrationButton"}
                                                style={{width: "90%"}}
                                                onClick={() => this.updateAllCheckBox(true, "payoutAmountReceived")}>{localizedTexts.buttonAllPayoutsReceived}</button>
                                        : <span> </span>
                                        }
                                    </div>

                                    <div className={"col-md-6"}>
                                        {
                                            (exercisesHaveAwardCount > 1) ?
                                                <button className={"btn btn-outline-primary registrationButton"}
                                                        style={{width: "100%"}}
                                                        onClick={() => this.updateAllCheckBox(true, "awardReceived")}>{localizedTexts.buttonAllNaturalAwardsReceived}</button>
                                                : <span> </span>
                                        }
                                        {
                                            (exercisesHaveAwardCardCount > 1) ?
                                                <button className={"btn btn-outline-primary registrationButton"}
                                                        style={{width: "100%", marginTop: '10px'}}
                                                        onClick={() => this.updateAllCheckBox(true, "awardCardReceived")}>{localizedTexts.buttonAllAwardCardsReceived}</button>
                                                : <span> </span>
                                        }
                                    </div>

                                </div>

                            </td>

                        </tr>
                        : <span> </span>

                }
                </tbody>
            </table>
        </div>
    }

    private getNaturalPayoutInfo(shootingProgramDefinition: ShootingProgramDefinition, e: ExerciseSettlement) {


        return <table>
            <tbody>
            <tr>
                <td>
                {
                        shootingProgramDefinition.hasNaturalPayout ? `${localizedTexts.labelSettlementNaturalPayout}: CHF ${e.naturalPayoutAmount?.toFixed(2)}` ?? "0.00" : ""
                    }
                </td>
            </tr>
            </tbody>
        </table>

    }

    private updateCheckBox = (newValue: boolean, attribute: "awardCardReceived" | "payoutAmountReceived" | "awardReceived" | "naturalPayoutAmountReceived", exerciseSettlement: ExerciseSettlement) => {
        var participant = this.state.participant;
        var settlements = participant?.exerciseSettlements;
        var settlement: any = settlements?.find(s => s.id == exerciseSettlement.id);
        if (settlement){
            settlement[attribute] = newValue;
        }
        this.setState({participant: participant})
    }

    private updateAllCheckBox = (newValue: boolean, attribute: "awardCardReceived" | "payoutAmountReceived" | "awardReceived" | "naturalPayoutAmountReceived") =>
    {
        this.setState(previousState => {
            let participant = {...previousState.participant};      // Creating a deep copy of participant.
            let settlements = participant?.exerciseSettlements;

            if (settlements && settlements.length > 0) {
                settlements.forEach((settlement) => {
                    if (settlement) {
                        switch(attribute) {
                            case "awardReceived":
                                settlement.awardCardReceived = false;
                                if (settlement.awardGranted) settlement[attribute] = newValue;
                                break;
                            case "awardCardReceived":
                                settlement.awardReceived = false;
                                if (settlement.awardCardGranted) settlement[attribute] = newValue;
                                break;
                            case "payoutAmountReceived":
                                if (settlement.payoutAmount != null && settlement.payoutAmount > 0) settlement[attribute] = newValue;
                                break;
                        }
                    }
                });
                participant.exerciseSettlements = settlements;

            }
            return {participant};
        });
    }

    private getAwardInfo(shootingProgramDefinition: ShootingProgramDefinition, e: ExerciseSettlement) {
        var hasAward = shootingProgramDefinition.hasAward || shootingProgramDefinition.hasAwardCard || shootingProgramDefinition.hasAwardNatural;
        var awardGranted = e.awardGranted || e.awardCardGranted;

        return <div>

            {
                hasAward ?
                    awardGranted ?
                        <div className={"row"}>
                            <div className={"col-md-5"}>
                                <span className={"bi-check-circle-fill text-success"}></span>
                            </div>
                            <div className={"col-md-6"}>
                                {
                                    e.awardGranted ?
                                        <div className={"row"}>
                                            <div className={"col-md-9"}>
                                                <input id={"awardReceived"} type={"checkbox"} checked={e.awardReceived}
                                                       readOnly={e.awardCardReceived}
                                                       disabled={e.awardCardReceived}
                                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => this.updateCheckBox(evt.currentTarget.checked, "awardReceived", e)}/>{shootingProgramDefinition.hasAwardNatural ? " " + shootingProgramDefinition.awardNaturalName ?? " Natural" : " Kranz"} bezogen
                                            </div>
                                        </div> : ""
                                }
                                {
                                    e.awardCardGranted ?
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <input id={"awardCardReceived"} type={"checkbox"}
                                                       checked={e.awardCardReceived}
                                                       readOnly={e.awardReceived}
                                                       disabled={e.awardReceived}
                                                       onChange={(evt: ChangeEvent<HTMLInputElement>) => this.updateCheckBox(evt.currentTarget.checked, "awardCardReceived", e)}/> Kranzkarte
                                                bezogen
                                            </div>
                                        </div> : ""
                                }

                            </div>
                            {
                                e.awardCardGranted && shootingProgramDefinition.hasAwardCardTemplateFile == true?
                                    <div className={"col-md-1"}>
                                        <button className={'btn btn-outline-dark'} style={{ marginLeft: '-35px', marginTop: '6px',}}
                                            onClick={()=>this.handlePrintAwardCard(this.state.participant?.participantNumber ?? "", e.shootingProgramDefinitionId ?? 0)}>
                                            <span className={"bi-printer-fill"}> </span>
                                        </button>
                                    </div> : ""
                            }
                        </div>
                        :
                        <div className={"row"}>
                            <span className={"bi-x-circle-fill text-danger"}></span>
                        </div>
                    : <span></span>
            }
        </div>
    }

    private getPayoutInfo(shootingProgramDefinition: ShootingProgramDefinition, e: ExerciseSettlement) {
        return <div>

            {
                shootingProgramDefinition.hasPayout ?
                    <div className={"row"}>
                        <div className={"col-md-5"}>
                                {
                                    e.payoutAmount == 0 ? <span className={"bi-x-circle-fill text-danger"}></span> : `${localizedTexts.labelSettlementPayout}: CHF ${e.payoutAmount?.toFixed(2)}` ?? ""
                                }
                        </div>
                        <div className={"col-md-7"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <input id={"awardReceived" } type={"checkbox"} checked={e.payoutAmountReceived} disabled={e.payoutAmount == 0}
                                           onChange={(evt: ChangeEvent<HTMLInputElement>) => this.updateCheckBox(evt.currentTarget.checked, "payoutAmountReceived", e)}/> Auszahlung
                                    bezogen
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        </div>
            }
        </div>
    }

    handleSaveButton = async () => {
        if (this.state.participant) {
            var participant = this.state.participant;
            try {
                var saveResult = await this.apiClient.api.eventsParticipantsUpdatesettlementUpdate(this.props.eventId ?? "", participant)
                if (saveResult.ok) {
                    this.handleNextShooter();
                }
            } catch (e: any) {

            }
        }
    }

    handleSaveAndPrintButton = async () => {
        if (this.state.participant) {
            var participant = this.state.participant;
            try {
                var saveResult = await this.apiClient.api.eventsParticipantsUpdatesettlementUpdate(this.props.eventId ?? "", participant)
                if (saveResult.ok) {
                    var printResult = await this.apiClient.api.eventsParticipantsAccountingsheetDetail(participant.participantNumber!, this.props.eventId ?? "");
                    if (printResult.ok) {
                        await PrintDocument.printPdfDocument(printResult)
                        this.handleNextShooter();
                    }
                }
            } catch (e: any) {

            }
        }
    }

    handleNextShooter = () => {
        this.setState({participant: undefined});
        this.reFocusShooterNumber()
    }

    reFocusShooterNumber = () => {
        document.getElementById("shooternumber")?.focus();
    }

    render() {
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

        return <div>
            <h1>{localizedTexts.title}</h1>
            <div className={"row p-3 g-3 shadow bg-body rounded"}>
                <div className={"col-md-4"}>
                    {this.getShooterInfo()}
                </div>
                <div className={"col-md-8"}>
                    {this.getShootingPrograms()}
                </div>
            </div>

            <div className={"row p-3 g-3 shadow bg-body rounded"}>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-dark registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleSaveButton()}>{localizedTexts.buttonSave}</button>
                </div>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-dark registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleSaveAndPrintButton()}>{localizedTexts.buttonSaveAndPrint}</button>
                </div>
                <div className={"col-md-3"}>
                    <button className={"btn btn-outline-primary registrationButton"} style={{width: "90%"}}
                            onClick={() => this.handleNextShooter()}>{localizedTexts.buttonNextParticipant}</button>
                </div>
            </div>
        </div>
    }
}

interface EventParticipantsSettlementState {
    eventRegistrationInformation: EventRegistratioInformation | undefined;
    participant: ParticipantSettlement | undefined;
    allWeapons: any;
    shootingProgramDefinitions: any;
    isLoading: boolean;
}

interface EventParticipantsSettlementProps {
    eventId: string | undefined;
    participantNumber: string | undefined;
}

export default EventParticipantsSettlementWrapper;