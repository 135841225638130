import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Übersicht",
        "welcometextheading":"Herzlich Willkommen im WinFire Core Modul",
        "welcometext":"Hier können sie die Mitglieder, Vereine und Schiessanlagen verwalten.",
        "textSiteLoading": "Seite lädt. Bitte einen Moment Geduld haben.",
    },
    "fr": {
        "title" : "aperçu",
        "welcometextheading":"Bienvenue dans le module WinFire Core",
        "welcometext":"Ici, vous pouvez gérer les membres, les associations et les stands de tir.",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant.",
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;