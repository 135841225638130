import React, {ChangeEvent, Component} from "react";
import localizedTexts from "./texts/EventRoundsOverviewRoundModal.text";
import {ReShotRequestBody, Round} from "./models/Models";
import WinFireDropDown from "../common/components/WinFireDropDown";
import {NavLink} from "react-router-dom";
import ApiClientFactory from "./models/ApiClientFactory";
import * as querystring from "node:querystring";

export default class EventRoundsOverviewRoundModal extends Component<EventRoundsOverviewRoundModalProps, EventRoundsOverviewRoundModalState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventRoundsOverviewRoundModalProps) {
        super(props);
        this.state = {
            name: "",
            number: 0,
            scheibe: 0,
            participantNr: 0,
            programNr: 0,
        }
    }

    handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;
        this.setState({ [name]: value } as unknown as
        Pick<EventRoundsOverviewRoundModalState, keyof EventRoundsOverviewRoundModalState>);
    };

    saveHandler = () => {
        var round = this.props.selectedRound;
        if (!round){

        }

        this.props.saveHandler(round);

        this.setState({
            name: "",
            number: 0,
        });
    }

    removeHandler = () => {
        this.props.saveHandler(undefined);
        this.setState({
            name: "",
            number: 0,
        });
    }

    addHandler = async () => {
        const programnr = this.state.programNr;
        const eventId = `${this.props.eventId}`;

        const queryParams = {programNr: programnr}
        var result = await this.apiClient.api.eventsRoundsLaneAddCreate(this.props.selectedRound?.roundNumber ?? 0, this.state.scheibe, this.state.participantNr, eventId,  queryParams);
        if (result.ok)
        {

        }
        else
        {
            alert("Failed to add participant to round " + this.props.selectedRound);
        }
    }

    private async startReShooingOnLane(roundNr: number, laneNr: number, participantNumber: string) {
        if (laneNr === 0 || participantNumber === "") return;

        if (window.confirm(localizedTexts.textConfirmReShooting! + laneNr)) {
            const params : ReShotRequestBody =  {
                    programNr : 222,
                    participantNr : participantNumber
            };
            var removeResult = await this.apiClient.api.eventsRoundsLaneReshootCreate(roundNr, laneNr,`${this.props.eventId}`,  params);
            if (removeResult.ok) {
            } else {
                alert("Fehlgeschlagen...");
            }
        }
    }


    render(){
        if (!this.props.ready) {
            return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1} aria-labelledby="roundEdit" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className={"spinner-border"} role={"status"}/>
                    </div>
                </div>
            </div>
        }
        const { scheibe, participantNr, programNr } = this.state;


        return <div id={this.props.modalId} className="modal fade modal-xl" tabIndex={-1}
                    aria-labelledby="roundEdit" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">

                        <h5 className="modal-title" id="roundModalLabel"> <span className="bi-text-wrap"></span> &nbsp;
                            {localizedTexts.title} {this.props.selectedRound?.roundNumber}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h3>{localizedTexts.labelParticipantsInRound}</h3>
                                </div>
                            </div>
                            <div className={"row p-2"}>
                                <table className={"table table-striped table-responsive"}>
                                    <thead>
                                    <tr>
                                        <th>Scheiben Nr.</th>
                                        <th>Schützennummer</th>
                                        <th>Nachname</th>
                                        <th>Vorname</th>
                                        <th>Jahrgang</th>
                                        <th>ProgramNr</th>
                                        <th>Sync.</th>
                                        <th>Aktionen</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.props.selectedRound?.roundAssignments?.map((r) => {
                                            return <tr>
                                                <td style={{textAlign: "right", width: "10%"}}>{r.laneNr}</td>
                                                <td><a href={"participants/register/" + r.participant?.participantNumber}> {r.participant?.participantNumber}</a> </td>
                                                <td>{r.participant?.person?.lastName}</td>
                                                <td>{r.participant?.person?.firstName}</td>
                                                <td>{new Date(r.participant?.person?.birthday!).getFullYear()}</td>
                                                <td>{r.programNr}</td>
                                                <td>
                                                    {r.isRegisteredOnConnector ?
                                                        <span className={"bi-check-circle-fill text-success"}></span>
                                                        :
                                                        <span className={"bi-check-circle-fill text-danger"}></span>
                                                    }
                                                </td>
                                                <td>
                                                    <a href={`/event/${this.props.eventId}/participants/result/${r.participant?.participantNumber}`}
                                                       className="btn btn-primary" role="button"  data-toggle="tooltip"
                                                       data-placement="top" title={localizedTexts.labelToResult}>
                                                        <span className={"bi-file-spreadsheet-fill"}> </span></a>
                                                    &nbsp;
                                                    &nbsp;
                                                    <button
                                                        className={"btn btn-warning"} data-toggle="tooltip"
                                                        data-placement="top" title={localizedTexts.labelAddShots}
                                                        onClick={() => this.startReShooingOnLane(this.props.selectedRound?.roundNumber ?? 0, r.laneNr ?? 0, r.participant?.participantNumber ?? "")}><span
                                                        className={"bi-clipboard2-plus"}> </span></button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <p>ScheibenNr.</p>
                        <input type="text" placeholder="Scheibe" style={{width: '75px'}}
                               name="scheibe" value={scheibe}
                               onChange={this.handleInputChange}/>

                        <p>SchützenNr.</p>
                        <input type="text" placeholder="Schützennummer"
                               name="participantNr" value={participantNr}
                               onChange={this.handleInputChange}/>

                        <p>ProgramNr.</p>
                        <input type="text" placeholder="ProgramNr." style={{width: '90px'}}
                               name="programNr" value={programNr}
                               onChange={this.handleInputChange}/>

                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                onClick={this.addHandler} style={{marginRight: '100px'}}>
                            {localizedTexts.labelAddRoundAssignment}
                        </button>

                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal"
                                onClick={this.saveHandler}>{localizedTexts.labelSave}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

}

interface EventRoundsOverviewRoundModalState {
    name: string;
    number: number;
    scheibe: number,
    participantNr: number,
    programNr: number
}

interface EventRoundsOverviewRoundModalProps{
    modalId: string;
    saveHandler: Function;
    rounds: Round[];
    ready: boolean;
    selectedRound: Round | undefined;
    eventId: string | undefined;
}