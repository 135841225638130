import {Api, UserData} from "../core/models/Models";

export default class UserProfileHandler {
    private static userData: UserData | undefined = undefined;

    private static async loadUserData(){
        let api = new Api({
            baseUrl: process.env.REACT_APP_API_LOCATION,
            baseApiParams: {
                credentials: "include",
                mode: "cors"
            }
        });
        let result = await api.api.coreUserDataList();
        if (result.ok){
            this.userData = result.data;
        }
    }

    static async GetUserData(){
        if (!this.userData){
            await this.loadUserData();
        }

        return this.userData;
    }
}