import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Schiessanlässe",
        "headerName":"Name",
        "headerType":"Anlass Art",
        "headerActions":"Aktionen",
        "newEvent":"Neuer Anlass erstellen",
        "toEvent":"Zum Anlass"
    },
    "fr": {
        "overview" : "Fêtes de tir",
        "headerName":"Nom",
        "headerType":"Manifestation type",
        "headerActions":"Actions",
        "newEvent":"Créer une nouvelle manifestation",
        "toEvent":"Vers manifestation"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;