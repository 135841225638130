import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Schiessanlagen",
        "headerId":"ID",
        "headerName":"Name",
        "headerCity":"Ort",
        "headerComment":"Kommentar",
        "headerActions":"Aktionen",
        "headerDiscipline":"Diszipline",
        "headerLastSeen":"Zuletzt online",
        "labelLanes":"Scheiben",
        "labelNewRange": "Neue Schiessanlage erfassen",
        "shootingPrograms" : "Schiessprogramme",
        "settings": "Einstellungen",
        "textSiteLoading": "Seite lädt. Bitte einen Moment Geduld haben.",
        "eventSettingsDescription" : "Diese Einstellungen werden beim Deaktivieren eines Events programmiert",
        "save" : "Speichern",
        "nosettingsmanuelmode" : "Für manuelle Anlagen sind keine weitere Einstellungen verfügbar",
    },
    "fr": {
        "title" : "stand de tir",
        "headerId":"ID",
        "headerName":"nom",
        "headerCity":"lieu",
        "headerComment":"commentaire",
        "headerActions":"activités",
        "headerDiscipline":"disciplines",
        "headerLastSeen":"dernier en ligne",
        "labelLanes":"cibles",
        "labelNewRange": "enregistrer un nouveaux stand de tir",
        "shootingPrograms" : "exercises",
        "settings" : "paramètres",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant.",
        "eventSettingsDescription" : "Ces paramètres sont programmés lors de la désactivation d'un événement",
        "save" : "enregistrer",
        "nosettingsmanuelmode" : "Aucun autre paramètres n'est disponible pour le mode manuel",
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;