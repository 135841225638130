import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "ShootingRange" : "Schiessanlagen",
        "DisplayName":"Bildschirmname",
        "Save":"Speichern",
        "ScreenRegister":"Bildschirm registrieren",
        "PleaseSelect":"Bitte auswählen",
        "ScreenRegistert":"Bildschirm ist registriert",
        "headerDiscipline":"Diszipline",
        "labelLanes":"Scheiben",
        "labelNewRange": "Neue Schiessanlage erfassen"
    },
    "fr": {

    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;