import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "register":"Anmeldung",
        "result":"Resultaterfassung",
        "resultByProgram":"Stichweise Resultaterfassung",
        "settlement":"Abrechnung"
    },
    "fr": {
        "overview" : "Aperçu",
        "register":"Inscription",
        "result":"Enregistrement des résultats",
        "settlement":"Décompte"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;