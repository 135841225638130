import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "labelSelection":"Auswahl",
        "labelName":"Schiessstand",
        "labelNumber":"Nummer",
        "labelDescription":"Beschreibung",
        "labelDiscipline":"Disziplin",
        "labelCity":"Ort",
        "labelSave":"Speichern",
        "labelAbort":"Abbrechen"
    },
    "fr": {
        "labelSelection":"Sélection",
        "labelName":"Stand de tir",
        "labelNumber":"Nombre",
        "labelDescription":"Description",
        "labelDiscipline":"Discipline",
        "labelCity":"Lieu",
        "labelSave":"Enregistrer",
        "labelAbort":"Annuler"
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;