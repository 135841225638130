import * as React from 'react';
import localizedTexts from "./texts/MainNavigation.Text";
import {NavLink} from "react-router-dom";
import Login from "./Login";

function MainNavigation(){

    return <nav className={"navbar navbar-expand-lg bg-winfire"}>
        <div className={"container-fluid"}>
            <NavLink to={"/"} className={"navbar-brand"}>
                <img src={"/logo192.png"} className={"d-inline-block"} alt={"WinFire Logo"} height={'40px'}  />
                &nbsp;WinFire
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div id={"navbarSupportedContent"} className={"collapse navbar-collapse bg-winfire-subtle"}>
                <ul className={"navbar-nav navbar-nav-scroll"}>
                    <li className={"nav-item"}>
                        <NavLink className={"nav-link"} to={"/"}>{localizedTexts.home}</NavLink>
                    </li>
                    <li className={"nav-item"}>
                        <NavLink className={"nav-link"} to={"/core/overview"}>{localizedTexts.core}</NavLink>
                    </li>
                    <li className={"nav-item"}>
                        <NavLink className={"nav-link"} to={"/event/"}>{localizedTexts.event}</NavLink>
                    </li>
                </ul>
                <Login/>
            </div>
        </div>
    </nav>
}


export default MainNavigation;