import {Component} from "react";
import {NavLink} from "react-router-dom";
import localizedTexts from "../texts/EventManagementNavigation.texts";

function EventManagementNavigationWrapper(){

    return <EventManagementNavigation />
}

export class EventManagementNavigation extends Component<EventManagementNavigationProps, EventManagementNavigationState>{

    render(){
        return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
            <NavLink to={"overview"} className="btn btn-outline-dark">
                <span className={"bi-book"}> </span>{localizedTexts.overview}
            </NavLink>
            <NavLink to={"shootingranges"} className="btn btn-outline-dark">
                <span className={"bi-book"}> </span>{localizedTexts.shootingranges}
            </NavLink>
        </div>
    }
}

interface EventManagementNavigationState {

}

interface EventManagementNavigationProps {

}

export default EventManagementNavigationWrapper;